<div class="pageContainer">
    <div class="loginFormWrap" *ngIf = "appcode">
        <div class="loginLogoWrap">
            <img class="loginLogo" src="../../../assets/appLogo.png" alt="appLogo">
        </div>
        <h6 class="loginHeader">{{'Appcode.Header' | translate}}</h6>
        <p class="loginHeader">{{'Appcode.Login' | translate}}</p>
        <div class="formWrap">
            <form *ngIf="formLogin" [formGroup]="formLogin" (ngSubmit)="validateCode()">
                <div class="formRow">
                    <div class="formRow__Inner">
                        <input type="text" name="appCode" id="appCode" [placeholder]="'Appcode.AppCode' | translate" formControlName="appCode"
                            [ngClass]="{'invalid': !formLogin.controls.appCode.valid && isSubmitted}" />
                    </div>
                    <div class="errorText" *ngIf="!formLogin.controls.appCode.valid && isSubmitted">
                        {{'Appcode.ErrorValidAppCode' | translate}}
                    </div>
                </div>
                <div class="formRow mt15">
                    <input type="submit" class="button loginButton" [ngClass]="{'disabled': disabled}"
                        name="loginButton" id="loginButton" [value]="'Appcode.Continue' | translate" [disabled]="disabled" />
                    <!-- <span class="infoIcon">i</span> -->
                </div>
            </form>
        </div>
        <p class="assistanceText mt15">
            <span class="infoIcon">i</span>
            <span class="assistanceTextContent ml5">
                {{'Appcode.CompanySpecificMessage' | translate}} <br />{{'Appcode.ContactAdministrator' | translate}}
                <a href="mailto:{{contactUsEmail}}">{{'Appcode.EmailUs' | translate}}</a><br /><br />
                {{'Appcode.WantToKnowMore' | translate}} <a href="https://www.biworldwide.com/employee-engagement/" target="blank">
                    {{'Appcode.VisitUs' | translate}}
                </a>
            </span>
        </p>

        <div class="otlkToast error" *ngIf="errorMessage && errorMessage!=''">
            <div class="otlkToastInner">
                <div class="otlkToastClose">
                    <i class="gg-close otlkToastCloseIcon"></i>
                </div>
                <div class="otlkToastMessage">{{errorMessage}}</div>
                <div class="otlkToastCloseAction">
                    <i class="gg-close otlkToastCloseActionIcon" (click)="closeToast()"></i>
                </div>
            </div>
        </div>
    </div>
</div>