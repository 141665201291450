import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from './url.service';
import { observable, Observable } from 'rxjs';

@Injectable()
export class AdvanceSearchService extends BaseUrlService {
    constructor(public http: HttpClient, public router: Router) {
        super(router);
    }

    advanceSearch(criteria: string, value: string): Observable<any> {
        this.checkBaseUrl();
        return this.http.get(`${this.instanseUrl}/mobileapp/outlook/${criteria}.action?${criteria}=${value}`);
    }
}