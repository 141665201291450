import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as fromAction from '@store/actions/recognition.action';
import * as fromStore from '@app/store';
import { ParticipantDetail } from '@app/models/participant-response.model';
import { Message } from '@app/models/message.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EligiblePromotion } from '@app/models/promotion-response.model';

@Component({
    selector: 'message-modal',
    templateUrl: './message-modal.component.html',
    styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
    public isAllRecipCheckboxChecked: boolean = true;
    public messageLength: number = 0;
    public isPreviousStateSet: boolean = false;
    public selectedParticipant: ParticipantDetail[];
    public message: Message;
    public previousParticipantState: ParticipantDetail[];
    public previousMessageState: Message;
    public balanceError: boolean = false;
    public pointError: boolean = false;
    public messageError: boolean = false;
    public allOpted: boolean = false;
    public noBudget: boolean = false;
    public copyOthers: boolean = false;
    public managerChecked: boolean = false;
    public meChecked: boolean = false;
    public copyOthersSearch: boolean = false;
    public emailValidation: boolean = true;
    public showError: boolean = false;
    public copyOthersError: string;
    public copyOthersParticipants: ParticipantDetail[];
    public copyOthersEmail : string;
    public copySearchValue: string;
    public isParticipantModalOpen: boolean = false;
    public showRATab: boolean;
    public filterName:string;
    public managerCheck: boolean;
    public meCheck: boolean;

    @Input() selectedPromotion: EligiblePromotion;
    @Output() closeModal = new EventEmitter();
    @Output() saveCopyModal = new EventEmitter();

    constructor(private store: Store<fromStore.ProductState>, private modal: NgbModal) { }

    ngOnInit() {
        this.store.select(fromStore.selectedPromotionState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            this.selectedPromotion = state.promotion;
            this.showRATab = state.showRATab;
            
        });
       
        this.store.select(fromStore.recognitionState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            
            if(this.selectedPromotion.budgetInfo == null){
                this.noBudget = true;
            }
            this.copyOthersParticipants = state.copyOthersParticipants;
            this.filterName = ""
            this.selectedParticipant = state.participants;
            this.message = state.message;  
            if(this.selectedPromotion.copyManagerAlways || state.message.sendCopyToManager === "on"){
                this.managerCheck = true
            }
            else{
                this.managerCheck = false
            }
            if(state.message.sendCopyToMe === "on"){
                this.meCheck = true;
            }
            else {
                this.meCheck = false;
            }
            this.copyOthersEmail = state.message.sendCopyToOthers;
            if (!this.isPreviousStateSet) {
                this.previousParticipantState = state.participants;
                this.previousMessageState = state.message;
                this.isPreviousStateSet = true;
                let checkAll = this.previousParticipantState.filter(x => x.optOutAwards).length > 0;
                this.checkOptedUsers();
                if (this.selectedPromotion.awardType == 'pointsFixed') {
                    let message = new Message(checkAll ? false : this.previousMessageState.applySamePoints, this.previousMessageState.comments,
                        this.previousMessageState.privateRecognition, this.selectedPromotion.awardFixed, this.previousMessageState.sendCopyToManager, this.previousMessageState.sendCopyToMe, this.previousMessageState.sendCopyToOthers);
                    this.updateMessage(message);
                    this.applySamePoints(this.selectedPromotion.awardFixed)
                }
                else {
                    if ( checkAll ) {
                        let message = new Message(false, this.previousMessageState.comments,
                            this.previousMessageState.privateRecognition, this.selectedPromotion.awardFixed, this.previousMessageState.sendCopyToManager, this.previousMessageState.sendCopyToMe, this.previousMessageState.sendCopyToOthers);
                        this.updateMessage(message);
                    }
                }
                
            }
        });
    }

    applySamePoints(points?: number) {        
        let tempPoints = null;
        if (points && !isNaN(Number(points)) && points >= 0) {
            tempPoints = points;
        }
        this.selectedParticipant.filter((val)=> val.optOutAwards === false).map(p => {
            let command = { id: p.id, points: Number(tempPoints) };
            this.store.dispatch({ type: fromAction.UPDATE_POINTS, payload: command });
        });
        let message = new Message(this.message.applySamePoints, this.message.comments, this.message.privateRecognition, tempPoints, this.message.sendCopyToManager, this.message.sendCopyToMe, this.message.sendCopyToOthers);
        this.updateMessage(message);
    }


    updatePoints(id: number, points?: number) {
        let tempPoints = null;
        if (points && !isNaN(Number(points)) && points >= 0)
            tempPoints = points

        let command = { id: id, points: Number(tempPoints) };
        this.store.dispatch({ type: fromAction.UPDATE_POINTS, payload: command });
    }
    closeParticipantModal() {
        this.copyOthersSearch = false;
    }
    participantSearch(value) {
        
        if(value.length>2){
            this.copySearchValue = value
            this.copyOthersSearch = true;
        }
    }
    checkOptedUsers() {
        let optedUsers = this.selectedParticipant.filter (({optOutAwards}) => optOutAwards === true).length;
        this.selectedParticipant.length == optedUsers ? this.allOpted = true : this.allOpted = false;        
    }

    custom(index, item) {
        return index;
    }

    handleAllRecipiants(value) {
        if (value) {
            this.selectedParticipant.filter((val)=> val.optOutAwards === false).map(p => {
                let command = { id: p.id, points: Number(this.message.points) };
                this.store.dispatch({ type: fromAction.UPDATE_POINTS, payload: command });
            });
        }
        let message = new Message(value, this.message.comments, this.message.privateRecognition, this.message.points, this.message.sendCopyToManager, this.message.sendCopyToMe, this.message.sendCopyToOthers);
        this.updateMessage(message);
    }

    handlePrivateRecognition(value) {
        let message = new Message(this.message.applySamePoints, this.message.comments, value, this.message.points, this.message.sendCopyToManager, this.message.sendCopyToMe, this.message.sendCopyToOthers);
        this.updateMessage(message);
    }
    handleSendManager(value: any){
        let val;
        if(value){
            val = "on"
        }
        else{
            val = "off"
        }
        let message = new Message(this.message.applySamePoints, this.message.comments, this.message.privateRecognition, this.message.points, val, this.message.sendCopyToMe, this.message.sendCopyToOthers);
        this.updateMessage(message);
    }
    handleSendMe(value: any){
        let val;
        if(value){
            val = "on"
        }
        else{
            val = "off"
        }
        let message = new Message(this.message.applySamePoints, this.message.comments, this.message.privateRecognition, this.message.points, this.message.sendCopyToManager, val, this.message.sendCopyToOthers);
        this.updateMessage(message);
    }
    handelSendOthers(value: string){
       
        let message = new Message(this.message.applySamePoints, this.message.comments, this.message.privateRecognition, this.message.points, this.message.sendCopyToManager, this.message.sendCopyToMe, value);
        this.updateMessage(message);
    }
    handleMessage(value) {
    
        let sendManagerVal
        if(this.selectedPromotion.copyManagerAlways){
            sendManagerVal ="on" 
        }
        else {
            sendManagerVal = "off"
        }
        let message = new Message(this.message.applySamePoints, value, this.message.privateRecognition, this.message.points, sendManagerVal, this.message.sendCopyToMe, this.message.sendCopyToOthers);
        this.updateMessage(message);
    }

    removeParticipant(id: number) {
        this.store.dispatch({ type: fromAction.REMOVE_PARTICIPANT, payload: id });
    }

    closeModalWindow() {
        this.store.dispatch({ type: fromAction.RESTORE_PARTICIPANT_TO_PREVIOUS_STATE, payload: this.previousParticipantState });
        this.updateMessage(this.previousMessageState);
        this.closeModal.emit();
    }

    saveMessageAndAwards() {
        if(this.copyOthersEmail){
        let copyOthersEmailArr = this.copyOthersEmail.split(",")
        let rgexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        let emailCheck = false
        for(let i in copyOthersEmailArr){
            let emailTrim = copyOthersEmailArr[i].replace(/\s/g, '');
            const emailValidation = rgexEmail.test(emailTrim)
            if(emailValidation) {
                emailCheck= true
                this.emailValidation = true
                this.copyOthersError = ""
                this.showError = false
            }
            else{
                emailCheck = false
                this.emailValidation = false
                this.showError = true
                this.copyOthersError = "Invalid Email Address. Please enter one or more email addresses separated by commas."
            }
        }
        if(emailCheck){
            this.handelSendOthers(this.copyOthersEmail)
        }
        }       
        
        this.balanceError = false;
        this.pointError = false;
        this.messageError = false;
        if (this.selectedPromotion.awardAvailable) {
            this.checkValidation();
            if (!this.balanceError && !this.pointError && !this.messageError && this.emailValidation)
                this.closeModal.emit();
        }
        else {
            if (!this.message.comments || this.message.comments.length > 2000)
                this.messageError = true;
            if (!this.messageError && this.emailValidation)
                this.closeModal.emit();
        }
    }

    checkValidation() {
        let balance = this.calculateRemainingPoints(this.selectedPromotion?.budgetInfo?.currentValue);
        if (balance < 0) {
            this.balanceError = true;
        }

        this.selectedParticipant.map(p => {
            if (!this.pointError) {
                if (!p.points ) {
                    !p.points && p.optOutAwards ? this.pointError = false : this.pointError = true;
                }                
                else {                    
                    if (this.selectedPromotion.awardType == 'pointsFixed') {
                        if (p.points != this.selectedPromotion.awardFixed)
                            this.pointError = true;
                    }
                    else {
                        if (p.points < this.selectedPromotion.awardMin || p.points > this.selectedPromotion.awardMax)
                            this.pointError = true;
                    }
                }
            }
        });

        if (!this.message.comments || this.message.comments.length > 2000)
            this.messageError = true;
    }

    checkAwardTypeValidation(points, optinout) {
        let returnValue = false;
        if (points) {
            returnValue = true;
        }
        else if ( !points && optinout ) {
            returnValue = false;
        }
        else {
            if (this.selectedPromotion.awardType == 'pointsFixed') {
                if (points != this.selectedPromotion.awardFixed)
                    returnValue = true;
            }
            else {
                if (points < this.selectedPromotion.awardMin || points > this.selectedPromotion.awardMax)
                    returnValue = true;
            }
        }
        return returnValue;
    }

    updateMessage(message: Message) {
        this.store.dispatch({ type: fromAction.UPDATE_MESSAGE, payload: message });
    }

    calculateAssignedPoints() {
        if (this.selectedParticipant.length == 0 || this.allOpted)
            return 0;

        let assignedPoints = this.selectedParticipant.filter((val) => val.optOutAwards === false).map(a => Math.round(a.points * a.countryRatio)).reduce(function (a, b) {
            return a + b;
        });
        if (!assignedPoints)
            return 0;

        return assignedPoints;
    }

    calculateRemainingPoints(currentValue) {
        if (this.selectedParticipant.length == 0 || this.allOpted)
            return currentValue;

        let assignedPoints = this.selectedParticipant.filter((val)=> val.optOutAwards === false).map(a => Math.round(a.points * a.countryRatio)).reduce(function (a, b) {
            return a + b;
        });
        return currentValue - assignedPoints;
    }

    calculateWidth(currentValue) {
        if (this.selectedParticipant.length == 0 || this.allOpted)
            return 100;
        let assignedPoints = this.selectedParticipant.filter((val)=> val.optOutAwards === false).map(a => Math.round(a.points * a.countryRatio)).reduce(function (a, b) {
            return a + b;
        });
        let width = ((currentValue - assignedPoints) / currentValue) * 100;
        if (width <= 0)
            width = 0;

        return width;
    }

    wholeNumberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
        return true;    
    }

    onPastePoints(event): any {
        let clipboardData = event.clipboardData;
        let pastedText = clipboardData.getData('text');
        if ( clipboardData ) {
            let numRegEx = /^[0-9]+$/;
            if( !numRegEx.test(pastedText) ) {                                
                return false;
            }            
            return true;            
        }  
    }

    findMessageLength(value: string) {
        this.messageLength = value.length;
    }
    removeCopyOthersParticipant(participant){
        this.store.dispatch({ type: fromAction.REMOVE_COPYOTHERS_PARTICIPANT, payload: participant.id });
    }
    getShortName(firstName, lastName) {
        const fullName = firstName + ' ' + lastName;
        return fullName.split(' ').map(n => n[0]).join('');
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}