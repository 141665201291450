import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as fromAction from '@store/actions/recognition.action';
import * as fromStore from '@app/store';
import { Behaviors, EligiblePromotion } from '@app/models/promotion-response.model';

@Component({
    selector: 'behavior-modal',
    templateUrl: './behavior-modal.component.html',
    styleUrls: ['./behavior-modal.component.scss']
})
export class BehaviorModalComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
    @Input() selectedPromotion: EligiblePromotion;
    @Output() closeModal = new EventEmitter();

    constructor(private store: Store<fromStore.ProductState>) { }

    ngOnInit() {
        this.store.select(fromStore.recognitionState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {

        });
    }

    selectBehavior(behavior: Behaviors) {
        this.store.dispatch({ type: fromAction.UPDATE_BEHAVIOR, payload: behavior });
        this.closeModal.emit();
    }

    closeModalWindow() {
        this.closeModal.emit();
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}