<!-- Below is the loader div. This should be added as a direct child to "pageContainer" div. Show / hide this conditionally -->
<div class="otlkLoader">
    <div class="otlkLoaderInner">
        <div class="circle-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>           
        </div>
    </div>
</div>