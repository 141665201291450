import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EligiblePromotion } from '@model/promotion-response.model';
import * as fromStore from '@app/store';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { participantsSample } from './participants-sample';

import { FocusKeyManager } from "@angular/cdk/a11y";
import { ListItemComponent } from "../list-item/list-item.component";
import { DOWN_ARROW, ENTER } from '@angular/cdk/keycodes';

@Component({
    selector: 'recognition-page',
    templateUrl: './recognition.component.html',
    styleUrls: ['./recognition.component.scss']
})
export class RecognitionComponent implements OnInit, OnDestroy {
    private keyManager: FocusKeyManager<ListItemComponent>;
    @ViewChildren(ListItemComponent) listItems: QueryList<ListItemComponent>;

    faTimes = faTimes;

    private onDestroy$ = new Subject<void>();
    public selectedPromotion: EligiblePromotion;
    public isSearchFocus: boolean = false;
    public isToPopupOpen: boolean = true;
    public isMessagePopupOpen: boolean = false;
    public isBehaviorPopupOpen: boolean = false;
    public isVideoPopupOpen: boolean = false;
    public isAnyPopupOpen: boolean = true;
    public isAllRecipCheckboxChecked: boolean = true;

    public isToastOpen: boolean = false;
    public isUploading: boolean = false;

    public searchParticipants: [];

    constructor(private store: Store<fromStore.ProductState>, private router: Router) {
        this.searchParticipants = participantsSample.participants;
    }

    ngOnInit() {
        this.store.select(fromStore.getPromotionState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            this.selectedPromotion = state;
        });
    }

    ngAfterViewInit() {
        this.keyManager = new FocusKeyManager(this.listItems).withWrap();
    }

    cancel() {
        this.router.navigate(['/promotion']);
    }

    focusInput(): void {
        this.isSearchFocus = !this.isSearchFocus;
    }

    openModal(popupName): void {
        this[popupName] = !this[popupName];
        this.isAnyPopupOpen = !this.isAnyPopupOpen;
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    handleAllRecipCheckbox(event): void {
        this.isAllRecipCheckboxChecked = event.target.checked;
    }

    showToast(): void {
        this.isToastOpen = !this.isToastOpen;
    }

    getShortName(firstName, lastName) {
        const fullName = firstName + ' ' + lastName;
        return fullName.split(' ').map(n => n[0]).join('');
    }

    selectParticipant(participant) {
        console.log('Selected participant is :', participant);
    }

    onKeydown(event) {
        if (event.keyCode === ENTER) {
            this.selectParticipant( this.keyManager.activeItem.participant );

        } else {
          this.keyManager.onKeydown(event);
        }
    }

    onKeyup(event) {
        if ( event.keyCode === DOWN_ARROW ) {
            this.keyManager.setActiveItem(0);
        }
    }

}