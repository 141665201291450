import { Component, OnInit } from '@angular/core';
import { PromotionService } from '@service/promotion.service';
import { CatalogueResponse } from '@model/promotion-response.model';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'catalogue',
    template: '<div class="otlkRedirection">Redirecting...</div>'
})

export class CatalogueComponent implements OnInit {
    public appcode: string = "";
    public token: string = "";
    public type: string = "";
    public platform: string = "";

    constructor(private promotionService: PromotionService, private activatedRoute: ActivatedRoute,
        private authService: AuthService
    ) { }

    ngOnInit() {
        this.appcode = this.activatedRoute.snapshot?.params?.appcode;
        this.token = this.activatedRoute.snapshot?.params?.token;
        this.type = this.activatedRoute.snapshot?.params?.type;
        this.platform = this.activatedRoute.snapshot?.params?.platform;
        this.redirect(this.type);
        localStorage.removeItem('catalogue');
    }

    redirect(redeemType: string) {
        this.authService.fetchInstanceUrl(this.appcode).subscribe((response: any) => {
            localStorage.setItem('host', response.appProperties.host);
            localStorage.setItem('context', response.appProperties.context);
            localStorage.setItem('appcode', this.appcode);
            localStorage.setItem('token', this.token);
            this.promotionService.getCatalogueUrl(redeemType).subscribe((response: CatalogueResponse) => {
                if (this.platform == 'OfficeOnline')
                    localStorage.clear();
                if (!response.token || !response.url)
                    return;

                if (document.getElementById('catalogueForm') != null)
                    document.getElementById('catalogueForm').remove();

                let form = document.createElement('form');
                form.setAttribute('id', 'catalogueForm');
                //form.setAttribute('target', '_blank');
                form.setAttribute('action', `${response.url}`);
                form.setAttribute('method', 'post');

                let input = document.createElement('input');
                input.setAttribute('type', 'hidden');
                input.setAttribute('name', 'locator');
                input.setAttribute('value', response.token);

                form.appendChild(input);
                document.body.appendChild(form);
                form.submit();
                //Office.context.ui.messageParent('');
            }, error => {
                Office.context.ui.messageParent('');
            });
        }, (err) => {
            Office.context.ui.messageParent('');
        });
    }
}