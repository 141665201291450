import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as fromAction from '@store/actions/recognition.action';
import * as fromStore from '@app/store';
import { RecognitionResponse } from '@model/recognition-response.model';
import { ECardResponse } from '@model/ecard-response.model';
import { PromotionService } from '@app/services/promotion.service';
import { environment } from '@env/environment';
import { ShopPoints } from '@app/models/promotion-response.model';

@Component({
    selector: 'final-page',
    templateUrl: './final.component.html',
    styleUrls: ['./final.component.scss']
})
export class FinalComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
    public final: RecognitionResponse;
    public ecard: ECardResponse;
    public showPoints: ShopPoints;
    public showContent: boolean = false;
    public platform: string = Office.context.diagnostics.platform?.toString();

    constructor(private store: Store<fromStore.ProductState>, private router: Router, private promotionService: PromotionService) { }

    ngOnInit() {
        this.store.select(fromStore.recognitionState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            this.final = state.final;
            this.ecard = state.ecard;
            if (!this.final)
                this.router.navigate(['/promotion']);
        });
        this.getShopPoints();
    }

    getShopPoints() {
        this.promotionService.getShopPoints().subscribe((response: ShopPoints) => {
            this.showPoints = response;
        });
    }

    sendAnother() {
        this.store.dispatch({ type: fromAction.UPDATE_FINAL_MESSAGE, payload: null });
        this.router.navigate(['/promotion']);
    }

    getContext() {
        if (localStorage.getItem('context'))
            return localStorage.getItem('context')
    }

    imageLoaded() {
        this.showContent = true;
    }

    redirect() {
        localStorage.setItem('public', 'true');
        var dialogPublic;
        Office.context.ui.displayDialogAsync(`${environment.domain}/#/public/${localStorage.getItem('appcode')}/${localStorage.getItem('token')}/${this.platform}`, { height: 100, width: 100, promptBeforeOpen: false }, (asyncResult) => {
            dialogPublic = asyncResult.value;
            dialogPublic.addEventHandler(Office.EventType.DialogMessageReceived, (arg) => {
                dialogPublic.close();
            });
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}