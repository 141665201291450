import { Action } from '@ngrx/store';

import { AdvanceSearch, ParticipantDetail, UpdateParticipantPointsCommand } from '@model/participant-response.model';
import { Message } from '@model/message.model';
import { Behaviors } from '@model/promotion-response.model';
import { ECardResponse } from '@model/ecard-response.model'
import { RecognitionResponse } from '@model/recognition-response.model';
import { EcardCategorySelection } from '@model/ecard-category-selection.model';

export const ADD_PARTICIPANT = 'Add Promotion';
export const COPYOTHERS_PARTICIPANT = 'Add Copyothers';
export const REMOVE_PARTICIPANT = 'Remove Promotion';
export const REMOVE_COPYOTHERS_PARTICIPANT = 'Remove Copyothers'
export const RESTORE_PARTICIPANT_TO_PREVIOUS_STATE = "Restore Participant To Previous State";
export const RESTORE_PARTICIPANT_ID_PREVIOUS_STATE = "Restore Participant To Previous State";
export const RESTORE_COPYOTHERSPARTICIPANT_TO_PREVIOUS_STATE = "Restore Copy Others Participant To Previous State";
export const PARTICIPANT_ID = "Participant ids"
export const REMOVE_PARTICIPANT_ID = "Remove participant ids"
export const UPDATE_POINTS = "Update Points";

export const UPDATE_MESSAGE = 'Update Message';

export const UPDATE_BEHAVIOR = 'Update Behavior';

export const UPDATE_ECARD = 'Update Ecard';

export const UPDATE_ECARD_CATEGORY = 'Update Ecard Category';

export const UPDATE_FINAL_MESSAGE = "Update Final Message";

export const UPDATE_TOAST = "Update Toast";

export const UPDATE_ADVANCE_SEARCH = "Update Advance Search";

export class AddParticipant implements Action {
    readonly type = ADD_PARTICIPANT;

    constructor(public payload: ParticipantDetail) { }
}

export class AddCopyothersParticipant implements Action {
    readonly type = COPYOTHERS_PARTICIPANT;

    constructor(public payload: ParticipantDetail) { }
}

export class ParticipantID implements Action {
    readonly type = PARTICIPANT_ID;

    constructor(public payload: Array<any>) { }
}

export class RemoveParticipantID implements Action {
    readonly type = REMOVE_PARTICIPANT_ID;

    constructor(public payload: number) { }
}

export class RemoveParticipant implements Action {
    readonly type = REMOVE_PARTICIPANT;

    constructor(public payload: number) { }
}

export class RemoveCopyothersParticipant implements Action {
    readonly type = REMOVE_COPYOTHERS_PARTICIPANT;

    constructor(public payload: number) { }
}

export class RestoreParticipantToPreviousState implements Action {
    readonly type = RESTORE_PARTICIPANT_TO_PREVIOUS_STATE;

    constructor(public payload: ParticipantDetail[]) { }
}
export class RestoreParticipantIdPreviousState implements Action {
    readonly type = RESTORE_PARTICIPANT_ID_PREVIOUS_STATE;

    constructor(public payload: Array<any>) { }
}
export class RestoreCopyOthersParticipantToPreviousState implements Action {
    readonly type = RESTORE_COPYOTHERSPARTICIPANT_TO_PREVIOUS_STATE;

    constructor(public payload: ParticipantDetail[]) { }
}

export class UpdateAdvanceSearch implements Action {
    readonly type = UPDATE_ADVANCE_SEARCH;

    constructor(public payload: AdvanceSearch) { }
}

export class UpdatePoints implements Action {
    readonly type = UPDATE_POINTS;

    constructor(public payload: UpdateParticipantPointsCommand) { }
}

export class UpdateMessage implements Action {
    readonly type = UPDATE_MESSAGE;

    constructor(public payload: Message) { }
}

export class UpdateBehavior implements Action {
    readonly type = UPDATE_BEHAVIOR;

    constructor(public payload: Behaviors) { }
}

export class UpdateEcardCategory implements Action {
    readonly type = UPDATE_ECARD_CATEGORY;

    constructor(public payload: EcardCategorySelection) { }
}

export class UpdateEcard implements Action {
    readonly type = UPDATE_ECARD;

    constructor(public payload: ECardResponse) { }
}

export class UpdateFinalMessage implements Action {
    readonly type = UPDATE_FINAL_MESSAGE;

    constructor(public payload: RecognitionResponse) { }
}

export class UpdateToast implements Action {
    readonly type = UPDATE_TOAST;

    constructor(public payload: RecognitionResponse) { }
}

export type PromotionAction = AddParticipant | AddCopyothersParticipant |RemoveParticipant | RemoveCopyothersParticipant | RestoreParticipantToPreviousState | RestoreCopyOthersParticipantToPreviousState |UpdatePoints |
    UpdateMessage | UpdateBehavior | UpdateEcard | UpdateEcardCategory | UpdateFinalMessage | UpdateToast | UpdateAdvanceSearch | ParticipantID | RemoveParticipantID | RestoreParticipantIdPreviousState;