import { Component, OnInit } from '@angular/core';
import { PromotionService } from '@service/promotion.service';
import { CustomPromoResponse } from '@model/promotion-response.model';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'custompromo',
    template: '<div class="otlkRedirection">Redirecting...</div>'
})

export class CustomPromoComponent implements OnInit {
    public appcode: string = "";
    public token: string = "";
    public promoID: string = "";
    public platform: string = "";
    constructor(private promotionService: PromotionService, private activatedRoute: ActivatedRoute,
        private authService: AuthService
    ) { }

    ngOnInit() {
        this.appcode = this.activatedRoute.snapshot?.params?.appcode;
        this.token = this.activatedRoute.snapshot?.params?.token;
        this.platform = this.activatedRoute.snapshot?.params?.platform;
        this.promoID = this.activatedRoute.snapshot?.params?.promoID;
        this.redirect(this.promoID);
        localStorage.removeItem('custompromo');
    }

    redirect(promoId: string) {
        this.authService.fetchInstanceUrl(this.appcode).subscribe((response: any) => {
            localStorage.setItem('host', response.appProperties.host);
            localStorage.setItem('context', response.appProperties.context);
            localStorage.setItem('appcode', this.appcode);
            localStorage.setItem('token', this.token);
            this.promotionService.getCustomPromoUrl(promoId).subscribe((response: CustomPromoResponse) => {
                if (this.platform == 'OfficeOnline')
                    localStorage.clear();
                if (!response.url)
                    return;
                window.location.href = response.url;
            }, error => {
                Office.context.ui.messageParent('');
            });
        }, (err) => {
            Office.context.ui.messageParent('');
        });
    }
}