import * as fromTranslation from '../actions/translate.action';

export interface TranlationState {
    language: string
}

export const initialState: TranlationState = {
    language: null
}


export function reducer(state = initialState, action: fromTranslation.TranslateAction): TranlationState {

    switch (action.type) {
        case fromTranslation.CHANGE_LANGUAGE: {
            return Object.assign({}, state, { language: action.payload })
        }        
    }

    return state;
}

export const getLanguage = (state: TranlationState) => state.language;