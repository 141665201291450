import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '@service/auth.service';
import * as fromStore from '@app/store';
import * as fromTranslationAction from '@store/actions/translate.action';
import { SsoConfiguration } from '@model/login-response.model';
import { environment } from '@env/environment';

@Component({
    selector: 'appcode-page',
    templateUrl: './appcode.component.html',
    styleUrls: ['./appcode.component.scss']
})
export class AppCodeComponent implements OnInit {
    public formLogin: FormGroup;
    public isSubmitted: boolean = false;
    public errorMessage: string;
    public disabled: boolean = false;
    public appcode: boolean = false;
    public contactUsEmail: string;
    public resetButton: boolean = true;

    constructor(private router: Router, private fb: FormBuilder, private authService: AuthService, private translateService: TranslateService,
        private store: Store<fromStore.ProductState>) {
        this.formLogin = this.fb.group({
            appCode: ['', [Validators.required]]
        });
    }

    ngOnInit() {
        let displayLanguage = Office.context.displayLanguage;
        let currentEmailId = Office.context && Office.context?.mailbox?.userProfile?.emailAddress ? Office.context?.mailbox?.userProfile?.emailAddress : 'biwrecognitionapp@biworldwide.com';
        let currentDomain = currentEmailId.split( '@' )[1];
        this.store.dispatch({ type: fromTranslationAction.CHANGE_LANGUAGE, payload: displayLanguage });
        if (localStorage.getItem('appcode')) {
            if(currentDomain === localStorage.getItem('appcode')){
                this.resetButton = false;
            }
            if (localStorage.getItem('host') && localStorage.getItem('context'))
                this.checkSso();
            else
                this.getInstanceUrl(localStorage.getItem('appcode'));
        }
        else{
            this.bypassAppcode(currentDomain)
        }  
        if (localStorage.getItem('domain')) {
            this.setContactUsEmail(localStorage.getItem('domain'));
        }
        else {
            this.getSupportEmail( currentDomain );
        }
    }

    validateCode() {
        this.errorMessage = '';
        this.isSubmitted = true;
        if (this.formLogin.valid) {
            this.disabled = true;
            this.resetButton = true;
            this.getInstanceUrl(this.formLogin.value.appCode)
        }
    }

    bypassAppcode(appcode) {
        this.resetButton = false;
        // console.log("bypassappcode")
        this.authService.getInstanceUrl(appcode).subscribe((response: boolean) => {
            if (response) {
                this.checkSso();
            }   
            else {
                this.disabled = false; 
                this.appcode = true;          
            }
        }, error => {
            this.disabled = false;
        });
    }

    getInstanceUrl(appcode) {
        this.authService.getInstanceUrl(appcode).subscribe((response: boolean) => {
            if (response)
                this.checkSso();
            else {
                this.disabled = false;
                this.translateService.get('Appcode.InvalidAppCode').subscribe((res)=>{
                    this.errorMessage = res;
                });                
            }
        }, error => {
            this.disabled = false;
        });
    }

    getSupportEmail(domain) {
        this.authService.getSupportEmail(domain).subscribe((response: any) => {
            if (response) {                
                console.log('My Response', response);                
                this.setContactUsEmail(response);
            }                
           
        }, error => {
            console.log('Service Down. Setting Up Default Email Id');
            this.setContactUsEmail('biwrecognitionapp@biworldwide.com');
        });
    }

    setContactUsEmail(res) {
        this.contactUsEmail = res && res === 'null' ? 'biwrecognitionapp@biworldwide.com' : res;
    }

    checkSso() {
        this.authService.getSsoConfiguration().subscribe((response: SsoConfiguration) => {
            if (response.ssoenabled) {
                localStorage.setItem('ssoUrl', response.ssoUrl);
                this.router.navigate([`/login`,], { state: { reset: this.resetButton, sso: true } })
                //this.redirectAuthentication();
                this.appcode = true
            }
            else
                this.router.navigate([`/login`,], { state: { reset: this.resetButton } });
        }, error => {
            this.disabled = false;
        });
    }

    redirectAuthentication() {
        var dialog;
        Office.context.ui.displayDialogAsync(`${environment.domain}/#/sso`, { height: 50, width: 50, promptBeforeOpen: false }, (asyncResult) => {
            dialog = asyncResult.value;
            dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg) => {
                let response: any = JSON.parse(arg.message);
                dialog.close();
                if (response.success) {
                    localStorage.setItem('token', response.token);
                    localStorage.setItem('culture', response.culture);
                    this.store.dispatch({ type: fromTranslationAction.CHANGE_LANGUAGE, payload: response.culture });
                    this.router.navigate([`/promotion`]);
                }
                else {
                    this.disabled = false;
                    this.errorMessage = response.error;
                    localStorage.clear();
                }
            });
        });
    }

    closeToast() {
        this.errorMessage = "";
    }
}