import { Action } from '@ngrx/store';

import { EligiblePromotion, FileValidation } from '@model/promotion-response.model';

export const SELECTED_PROMOTION = 'Selected Promotion';
export const UNSELECTED_PROMOTION = 'Unselected Promotion';
export const CHANGE_RTA_STATUS = 'Change RTA Status';
export const CHANGE_FILE_UPLOAD_SIZE = 'Change File Upload Size';
export const ALLOW_OWN_CARD = 'Allow Own Card'

export class SelectedPromotion implements Action {
    readonly type = SELECTED_PROMOTION;

    constructor(public payload: EligiblePromotion){}
}

export class UnSelectedPromotion implements Action {
    readonly type = UNSELECTED_PROMOTION;

    constructor(){}
}

export class ChangeRTAStatus implements Action {
    readonly type = CHANGE_RTA_STATUS;

    constructor(public payload: boolean){}
}

export class ChangeFileUploadSize implements Action {
    readonly type = CHANGE_FILE_UPLOAD_SIZE;

    constructor(public payload: FileValidation){}
}

export class AllowOwnCard implements Action {
    readonly type = ALLOW_OWN_CARD;

    constructor(public payload: boolean){}
}

export type PromotionAction = SelectedPromotion | UnSelectedPromotion | ChangeRTAStatus | ChangeFileUploadSize | AllowOwnCard;