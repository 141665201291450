<div class="modal-body">
    <div class="sub-title">
        <h4 class="modal-title">{{'Participants.AdvancedFilter.Filters' | translate}}</h4>
        <span class="close pull-right" (click)="activeModal.close()">
            <svg id="Close" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 25">
                <rect id="Rectangle_47" data-name="Rectangle 47" width="25" height="25" rx="3" fill="#fff" />
                <g id="x" transform="translate(7.5 7.5)">
                    <line id="Line_13" data-name="Line 13" x1="10" y2="10" fill="none" stroke="#484644"
                        stroke-width="1" />
                    <line id="Line_14" data-name="Line 14" x2="10" y2="10" fill="none" stroke="#484644"
                        stroke-width="1" />
                </g>
            </svg>
        </span>
    </div>
    <div class="modal-section">
        <form class="filter" [formGroup]="filterForm">

            <div class="searchWrap">
                <div class="searchWrapSearch mt-1">
                    <div class="labelDiv">
                        <label>{{'Participants.AdvancedFilter.JobTitle' | translate}}</label>
                        <label *ngIf="jobTitleList.selectedValue" class="pull-right color-blue" (click)="clearSelectedItem('jobTitle')">Clear</label>
                    </div>
                    <span class="searchWrapSearchIconHolder">
                        <i class="gg-search searchWrapSearchIcon" *ngIf="!jobTitleList.selectedValue"></i>
                        <input type="text" class="searchWrapSearchInput" formControlName="jobTitle" (focus)="disableCategoryies('jobTitle')"
                            *ngIf="!jobTitleList.selectedValue" />
                            <span class="searchWrapSearchMiniLoader modalFilter">
                                <miniloader *ngIf="jobTitleList.isLoading"></miniloader>
                            </span>
                        
                        <span class="otlkBadge otlkBadgeWithClose dib ml5" *ngIf="jobTitleList.selectedValue">
                            <span>{{jobTitleList.selectedValue}}</span>
                            <span class="otlkBadgeClose" (click)="removeParticipant('jobTitle')">

                                <svg id="Close" xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                    viewBox="0 0 25 25">
                                    <rect id="Rectangle_47" data-name="Rectangle 47" width="1" height="1" rx="3"
                                        fill="#fff" />
                                    <g id="x" transform="translate(8 8)">
                                        <line id="Line_13" data-name="Line 13" x1="10" y2="10" fill="none"
                                            stroke="#484644" stroke-width="1" />
                                        <line id="Line_14" data-name="Line 14" x2="10" y2="10" fill="none"
                                            stroke="#484644" stroke-width="1" />
                                    </g>
                                </svg>

                            </span>
                        </span>
                        <span class="fixedIcon">

                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="6" viewBox="0 0 11 6">
                                <path id="Path_10750" data-name="Path 10750"
                                    d="M.5,0A.477.477,0,0,1,.852.141l5,5A.479.479,0,0,1,6,5.492a.49.49,0,0,1-.148.36l-5,5A.48.48,0,0,1,.5,11a.479.479,0,0,1-.351-.148A.509.509,0,0,1,0,10.492a.48.48,0,0,1,.149-.351L4.8,5.5.149.852A.491.491,0,0,1,0,.492.457.457,0,0,1,.149.148.479.479,0,0,1,.5,0Z"
                                    transform="translate(11) rotate(90)" fill="#484644" />
                            </svg>

                        </span>
                        <span class="searchWrapSearchMiniLoader modalFilter">
                        </span>
                    </span>
                </div>
                <div class="searchWrapResultsHolder" *ngIf="!jobTitleList.isHide">
                    <div class="searchWrapResults">
                        <ul class="userListWrapList" id="userListWrapList" tabindex="0">
                            <!-- To be repeated for each user -->
                            <list-item *ngFor="let items of jobTitleList.filteredList; let i = index"
                                class="userListWrapListItemComp" [participant]="items" [filterBy]="true"
                                (click)="selectedSearch(i, 'jobTitle')">
                            </list-item>
                            <li class="p-2" *ngIf="jobTitleList.filteredList.length == 0">{{'Participants.NoResultsFoundText' | translate}}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="searchWrap">
                <div class="searchWrapSearch mt-1">
                    <div class="labelDiv">
                        <label>{{'Participants.AdvancedFilter.Department' | translate}}</label>
                        <label *ngIf="departmentList.selectedValue" class="pull-right color-blue" (click)="clearSelectedItem('department')">Clear</label>
                    </div>
                    <span class="searchWrapSearchIconHolder">
                        <i class="gg-search searchWrapSearchIcon" *ngIf="!departmentList.selectedValue"></i>
                        <input type="text" class="searchWrapSearchInput" formControlName="department" (focus)="disableCategoryies('department')"
                            *ngIf="!departmentList.selectedValue" />
                            <span class="searchWrapSearchMiniLoader modalFilter">
                                <miniloader *ngIf="departmentList.isLoading"></miniloader>
                            </span>
                        <span class="otlkBadge otlkBadgeWithClose dib ml5" *ngIf="departmentList.selectedValue">
                            <span>{{departmentList.selectedValue}}</span>
                            <span class="otlkBadgeClose" (click)="removeParticipant('department')">

                                <svg id="Close" xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                    viewBox="0 0 25 25">
                                    <rect id="Rectangle_47" data-name="Rectangle 47" width="1" height="1" rx="3"
                                        fill="#fff" />
                                    <g id="x" transform="translate(8 8)">
                                        <line id="Line_13" data-name="Line 13" x1="10" y2="10" fill="none"
                                            stroke="#484644" stroke-width="1" />
                                        <line id="Line_14" data-name="Line 14" x2="10" y2="10" fill="none"
                                            stroke="#484644" stroke-width="1" />
                                    </g>
                                </svg>

                            </span>
                        </span>
                        <span class="fixedIcon">

                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="6" viewBox="0 0 11 6">
                                <path id="Path_10750" data-name="Path 10750"
                                    d="M.5,0A.477.477,0,0,1,.852.141l5,5A.479.479,0,0,1,6,5.492a.49.49,0,0,1-.148.36l-5,5A.48.48,0,0,1,.5,11a.479.479,0,0,1-.351-.148A.509.509,0,0,1,0,10.492a.48.48,0,0,1,.149-.351L4.8,5.5.149.852A.491.491,0,0,1,0,.492.457.457,0,0,1,.149.148.479.479,0,0,1,.5,0Z"
                                    transform="translate(11) rotate(90)" fill="#484644" />
                            </svg>

                        </span>                        
                    </span>
                </div>
                <div class="searchWrapResultsHolder" *ngIf="!departmentList.isHide">
                    <div class="searchWrapResults">
                        <ul class="userListWrapList" id="userListWrapList" tabindex="0">
                            <!-- To be repeated for each user -->
                            <list-item *ngFor="let items of departmentList.filteredList; let i = index"
                                class="userListWrapListItemComp" [participant]="items" [filterBy]="true"
                                (click)="selectedSearch(i, 'department')">
                            </list-item>
                            <li class="p-2" *ngIf="departmentList.filteredList.length == 0">{{'Participants.NoResultsFoundText' | translate}}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="searchWrap">
                <div class="searchWrapSearch mt-1">
                    <div class="labelDiv">
                        <label>{{'Participants.AdvancedFilter.Group' | translate}}</label>
                        <label *ngIf="groupList.selectedValue" class="pull-right color-blue" (click)="clearSelectedItem('group')">Clear</label>
                    </div>
                    <span class="searchWrapSearchIconHolder">
                        <i class="gg-search searchWrapSearchIcon" *ngIf="!groupList.selectedValue"></i>
                        <input type="text" class="searchWrapSearchInput" formControlName="group"  (focus)="disableCategoryies('group')"
                            *ngIf="!groupList.selectedValue" />
                            <span class="searchWrapSearchMiniLoader modalFilter">
                                <miniloader *ngIf="groupList.isLoading"></miniloader>
                            </span>
                        <span class="otlkBadge otlkBadgeWithClose dib ml5" *ngIf="groupList.selectedValue">
                            <span>{{groupList.selectedValue}}</span>
                            <span class="otlkBadgeClose" (click)="removeParticipant('group')">

                                <svg id="Close" xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                    viewBox="0 0 25 25">
                                    <rect id="Rectangle_47" data-name="Rectangle 47" width="1" height="1" rx="3"
                                        fill="#fff" />
                                    <g id="x" transform="translate(8 8)">
                                        <line id="Line_13" data-name="Line 13" x1="10" y2="10" fill="none"
                                            stroke="#484644" stroke-width="1" />
                                        <line id="Line_14" data-name="Line 14" x2="10" y2="10" fill="none"
                                            stroke="#484644" stroke-width="1" />
                                    </g>
                                </svg>

                            </span>
                        </span>
                        <span class="fixedIcon">

                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="6" viewBox="0 0 11 6">
                                <path id="Path_10750" data-name="Path 10750"
                                    d="M.5,0A.477.477,0,0,1,.852.141l5,5A.479.479,0,0,1,6,5.492a.49.49,0,0,1-.148.36l-5,5A.48.48,0,0,1,.5,11a.479.479,0,0,1-.351-.148A.509.509,0,0,1,0,10.492a.48.48,0,0,1,.149-.351L4.8,5.5.149.852A.491.491,0,0,1,0,.492.457.457,0,0,1,.149.148.479.479,0,0,1,.5,0Z"
                                    transform="translate(11) rotate(90)" fill="#484644" />
                            </svg>

                        </span>
                        <span class="searchWrapSearchMiniLoader modalFilter">
                        </span>
                    </span>
                </div>
                <div class="searchWrapResultsHolder" *ngIf="!groupList.isHide">
                    <div class="searchWrapResults">
                        <ul class="userListWrapList" id="userListWrapList" tabindex="0">
                            <!-- To be repeated for each user -->
                            <list-item *ngFor="let items of groupList.filteredList; let i = index"
                                class="userListWrapListItemComp" [participant]="items" [filterBy]="true"
                                (click)="selectedSearch(i, 'group')">
                            </list-item>
                            <li class="p-2" *ngIf="groupList.filteredList.length == 0">{{'Participants.NoResultsFoundText' | translate}}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="searchWrap">
                <div class="searchWrapSearch mt-1">
                    <div class="labelDiv">
                        <label>{{'Participants.AdvancedFilter.Country' | translate}}</label>
                        <label *ngIf="countryList.selectedValue" class="pull-right color-blue" (click)="clearSelectedItem('country')">Clear</label>
                    </div>
                    <span class="searchWrapSearchIconHolder">
                        <i class="gg-search searchWrapSearchIcon" *ngIf="!countryList.selectedValue"></i>
                        <input type="text" class="searchWrapSearchInput" formControlName="country"  (focus)="disableCategoryies('country')"
                            *ngIf="!countryList.selectedValue" />
                            <span class="searchWrapSearchMiniLoader modalFilter">
                                <miniloader *ngIf="countryList.isLoading"></miniloader>
                            </span>
                        <span class="otlkBadge otlkBadgeWithClose dib ml5" *ngIf="countryList.selectedValue">
                            <span>{{countryList.selectedValue}}</span>
                            <span class="otlkBadgeClose" (click)="removeParticipant('country')">

                                <svg id="Close" xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                    viewBox="0 0 25 25">
                                    <rect id="Rectangle_47" data-name="Rectangle 47" width="1" height="1" rx="3"
                                        fill="#fff" />
                                    <g id="x" transform="translate(8 8)">
                                        <line id="Line_13" data-name="Line 13" x1="10" y2="10" fill="none"
                                            stroke="#484644" stroke-width="1" />
                                        <line id="Line_14" data-name="Line 14" x2="10" y2="10" fill="none"
                                            stroke="#484644" stroke-width="1" />
                                    </g>
                                </svg>

                            </span>
                        </span>
                        <span class="fixedIcon">

                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="6" viewBox="0 0 11 6">
                                <path id="Path_10750" data-name="Path 10750"
                                    d="M.5,0A.477.477,0,0,1,.852.141l5,5A.479.479,0,0,1,6,5.492a.49.49,0,0,1-.148.36l-5,5A.48.48,0,0,1,.5,11a.479.479,0,0,1-.351-.148A.509.509,0,0,1,0,10.492a.48.48,0,0,1,.149-.351L4.8,5.5.149.852A.491.491,0,0,1,0,.492.457.457,0,0,1,.149.148.479.479,0,0,1,.5,0Z"
                                    transform="translate(11) rotate(90)" fill="#484644" />
                            </svg>

                        </span>
                        <span class="searchWrapSearchMiniLoader modalFilter">
                        </span>
                    </span>
                </div>
                <div class="searchWrapResultsHolder" *ngIf="!countryList.isHide">
                    <div class="searchWrapResults">
                        <ul class="userListWrapList" id="userListWrapList" tabindex="0">
                            <!-- To be repeated for each user -->
                            <list-item *ngFor="let items of countryList.filteredList; let i = index"
                                class="userListWrapListItemComp" [participant]="items" [filterBy]="true"
                                (click)="selectedSearch(i, 'country')">
                            </list-item>
                            <li class="p-2" *ngIf="countryList.filteredList.length == 0">{{'Participants.NoResultsFoundText' | translate}}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="searchWrap">
                <div class="searchWrapSearch mt-1">
                    <div class="labelDiv">
                        <label>{{'Participants.AdvancedFilter.Location' | translate}}</label>
                        <label *ngIf="locationList.selectedValue" class="pull-right color-blue" (click)="clearSelectedItem('location')">Clear</label>
                    </div>
                    <span class="searchWrapSearchIconHolder">
                        <i class="gg-search searchWrapSearchIcon" *ngIf="!locationList.selectedValue"></i>
                        <input type="text" class="searchWrapSearchInput" formControlName="location" (focus)="disableCategoryies('location')"
                            *ngIf="!locationList.selectedValue" />
                            <span class="searchWrapSearchMiniLoader modalFilter">
                                <miniloader *ngIf="locationList.isLoading"></miniloader>
                            </span>
                        <span class="otlkBadge otlkBadgeWithClose dib ml5" *ngIf="locationList.selectedValue">
                            <span>{{locationList.selectedValue}}</span>
                            <span class="otlkBadgeClose" (click)="removeParticipant('location')">

                                <svg id="Close" xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                    viewBox="0 0 25 25">
                                    <rect id="Rectangle_47" data-name="Rectangle 47" width="1" height="1" rx="3"
                                        fill="#fff" />
                                    <g id="x" transform="translate(8 8)">
                                        <line id="Line_13" data-name="Line 13" x1="10" y2="10" fill="none"
                                            stroke="#484644" stroke-width="1" />
                                        <line id="Line_14" data-name="Line 14" x2="10" y2="10" fill="none"
                                            stroke="#484644" stroke-width="1" />
                                    </g>
                                </svg>

                            </span>
                        </span>
                        <span class="fixedIcon">

                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="6" viewBox="0 0 11 6">
                                <path id="Path_10750" data-name="Path 10750"
                                    d="M.5,0A.477.477,0,0,1,.852.141l5,5A.479.479,0,0,1,6,5.492a.49.49,0,0,1-.148.36l-5,5A.48.48,0,0,1,.5,11a.479.479,0,0,1-.351-.148A.509.509,0,0,1,0,10.492a.48.48,0,0,1,.149-.351L4.8,5.5.149.852A.491.491,0,0,1,0,.492.457.457,0,0,1,.149.148.479.479,0,0,1,.5,0Z"
                                    transform="translate(11) rotate(90)" fill="#484644" />
                            </svg>

                        </span>
                        <span class="searchWrapSearchMiniLoader modalFilter">
                        </span>
                    </span>
                </div>
                <div class="searchWrapResultsHolder" *ngIf="!locationList.isHide">
                    <div class="searchWrapResults">
                        <ul class="userListWrapList" id="userListWrapList" tabindex="0">
                            <!-- To be repeated for each user -->
                            <list-item *ngFor="let items of locationList.filteredList; let i = index"
                                class="userListWrapListItemComp" [participant]="items" [filterBy]="true"
                                (click)="selectedSearch(i, 'location')">
                            </list-item>
                            <li class="p-2" *ngIf="locationList.filteredList.length == 0">{{'Participants.NoResultsFoundText' | translate}}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="bodyFooter">
                <div class="recogSectionsButtonsHolder">
                    <button class="otlkBtn otlkBtn--secondary otlkBtn--inverse mr10 flexGrow" (click)="reset()">
                        {{'Participants.AdvancedFilter.Reset' | translate}}
                    </button>
                    <button class="otlkBtn otlkBtn--primary flexGrow" (click)="apply()">
                        {{'Participants.AdvancedFilter.Apply' | translate}}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>