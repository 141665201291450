import { ActionReducerMap, createSelector } from '@ngrx/store';
import * as fromPromotion from './promotion.reducer';
import * as fromRecognition from './recognition.reducer';
import * as fromTranslation from './translate.reducer';

export interface ProductState {
    selectedPromotion: fromPromotion.PromotionState,
    recognition: fromRecognition.RecognitionState,
    translation: fromTranslation.TranlationState
}

export const reducers: ActionReducerMap<ProductState> = {
    selectedPromotion: fromPromotion.reducer,
    recognition: fromRecognition.reducer,
    translation: fromTranslation.reducer
}

export const selectedPromotionState = (state: ProductState) => state.selectedPromotion;
export const recognitionState = (state: ProductState) => state.recognition;
export const translationState = (state: ProductState) => state.translation;

export const getPromotionState = createSelector(selectedPromotionState, fromPromotion.getPromotion);
export const showRATab = createSelector(selectedPromotionState, fromPromotion.getRATab);

export const getParticipant = createSelector(recognitionState, fromRecognition.getRecognitionParticipant);

export const getLanguage = createSelector(translationState, fromTranslation.getLanguage);