import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faAngleDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AdvanceSearchService } from '@app/services/advance.search.service';
import { debounceTime, filter, switchMap } from 'rxjs/operators';

@Component({
    selector: 'modal-filter',
    templateUrl: './modal-filter.html',
    styleUrls: ['modal-filter.scss']
})
export class ModalFilter implements OnInit {
    @Input() parentObject: any;
    faTimes = faTimes;
    faArrowDown = faAngleDown;    
    jobTitleList = { "isHide": true, "selectedValue": '', "filteredList": [], isLoading: false };
    countryList = { "isHide": true, "selectedValue": '', "filteredList": [], isLoading: false };
    locationList = { "isHide": true, "selectedValue": '', "filteredList": [], isLoading: false };
    departmentList = { "isHide": true, "selectedValue": '', "filteredList": [], isLoading: false };
    groupList = { "isHide": true, "selectedValue": '', "filteredList": [], isLoading: false };
    advanceSearchCount: number;
    public filterForm: FormGroup;
    constructor(public modal: NgbModal, public activeModal: NgbActiveModal, private advSearchService: AdvanceSearchService) {
    }
    ngOnInit() {
        this.filterForm = new FormGroup({
            department: new FormControl({ value: this.parentObject.department, disabled: false }),
            location: new FormControl({ value: this.parentObject.location, disabled: false }),
            jobTitle: new FormControl({ value: this.parentObject.jobTitle, disabled: false }),
            group: new FormControl({ value: this.parentObject.group, disabled: false }),
            country: new FormControl({ value: this.parentObject.country, disabled: false })
        });
        this.setDefaultValues();
        this.advanceSearchCount = this.parentObject.count;
        this.onChanges();
    }
    setDefaultValues() {
        this.locationList.selectedValue = this.parentObject.location;
        this.departmentList.selectedValue = this.parentObject.department;
        this.jobTitleList.selectedValue = this.parentObject.jobTitle;
        this.countryList.selectedValue = this.parentObject.country;
        this.groupList.selectedValue = this.parentObject.group;
    }
    onChanges() {
        this.department.valueChanges.pipe(
            debounceTime(200),
            filter(value => {
                if (value.length > 2) {
                    this.departmentList.isLoading = true;
                    return true;
                } else {
                    this.departmentList.isHide = true;
                    this.departmentList.filteredList = [];
                }
            }),
            switchMap(x =>  this.advSearchService.advanceSearch('department', x) ),
        ).subscribe(res => {
            this.departmentList.filteredList = res.department
                .filter(x => (x.toLowerCase()).indexOf(this.department.value.toLowerCase()) > -1);                            
                if ( this.department.value ) {
                    this.departmentList.isHide = false;                    
                }
                this.departmentList.isLoading = false;
        });

        this.location.valueChanges.pipe(
            debounceTime(200),
            filter(value => {
                if (value.length > 2) {
                    this.locationList.isLoading = true;
                    return true;
                } else {
                    this.locationList.isHide = true;
                    this.locationList.filteredList = [];
                }
            }),
            switchMap(x =>  this.advSearchService.advanceSearch('location', x) ),
        ).subscribe(res => {
            this.locationList.filteredList = res.location
                .filter(x => (x.toLowerCase()).indexOf(this.location.value.toLowerCase()) > -1);  
                if ( this.location.value ) {
                    this.locationList.isHide = false;                    
                }                         
                this.locationList.isLoading = false;
        });

        this.jobTitle.valueChanges.pipe(
            debounceTime(200),
            filter(value => {                
                if (value.length > 2) {
                    this.jobTitleList.isLoading = true;
                    return true;
                } else {
                    this.jobTitleList.isHide = true;
                    this.jobTitleList.filteredList = [];                    
                }
            }),
            switchMap(x =>  this.advSearchService.advanceSearch('jobTitle', x) ),
        ).subscribe(res => {
            this.jobTitleList.filteredList = res.jobTitle
                .filter(x => (x.toLowerCase()).indexOf(this.jobTitle.value.toLowerCase()) > -1);  
                if ( this.jobTitle.value ) {
                    this.jobTitleList.isHide = false;                    
                }                         
                this.jobTitleList.isLoading = false;
            
        });


        this.country.valueChanges.pipe(
            debounceTime(200),
            filter(value => {
                if (value.length > 2) {
                    this.countryList.isLoading = true;
                    return true;
                } else {
                    this.countryList.isHide = true;
                    this.countryList.filteredList = [];                    
                }
            }),
            switchMap(x =>  this.advSearchService.advanceSearch('country', x) ),
        ).subscribe(res => {
            this.countryList.filteredList = res.country
                .filter(x => (x.toLowerCase()).indexOf(this.country.value.toLowerCase()) > -1);   
                if ( this.country.value ) {
                    this.countryList.isHide = false;                    
                }                        
                this.countryList.isLoading = false;
        });


        this.group.valueChanges.pipe(
            debounceTime(200),
            filter(value => {
                if (value.length > 2) {
                    this.groupList.isLoading = true;
                    return true;
                } else {
                    this.groupList.isHide = true;
                    this.groupList.filteredList = [];
                }
            }),
            switchMap(x =>  this.advSearchService.advanceSearch('group', x) ),
        ).subscribe(res => {
            this.groupList.filteredList = res.group
                .filter(x => (x.toLowerCase()).indexOf(this.group.value.toLowerCase()) > -1); 
                if ( this.group.value ) {
                    this.groupList.isHide = false;                    
                }                          
                this.groupList.isLoading = false;
        });
    }
    removeParticipant(str: string) {
        if (str.indexOf("location") == 0 || !str) {
            this.location.setValue("");
            this.locationList.selectedValue = '';
            this.locationList.isHide = true;
        }
        if (str.indexOf("department") == 0 || !str) {
            this.department.setValue("");
            this.departmentList.selectedValue = '';
            this.departmentList.isHide = true;
        }
        if (str.indexOf("jobTitle") == 0 || !str) {
            this.jobTitle.setValue("");
            this.jobTitleList.selectedValue = '';
            this.jobTitleList.isHide = true;
        }
        if (str.indexOf("country") == 0 || !str) {
            this.country.setValue("");
            this.countryList.selectedValue = '';
            this.countryList.isHide = true;
        }
        if (str.indexOf("group") == 0 || !str) {
            this.group.setValue("");
            this.groupList.selectedValue = '';
            this.groupList.isHide = true;
        }
    }
    disableCategoryies(str: string) {
        if ( str ) {            
            console.log('String=', str);
            if (str.indexOf("location") != 0 ) {
                this.location.setValue("");            
                this.locationList.isHide = true;                
            }
            if (str.indexOf("department") != 0 ) {
                this.department.setValue("");            
                this.departmentList.isHide = true;
            }
            if (str.indexOf("jobTitle") != 0) {
                this.jobTitle.setValue("");            
                this.jobTitleList.isHide = true;
            }
            if (str.indexOf("country") != 0) {
                this.country.setValue("");            
                this.countryList.isHide = true;
            }
            if (str.indexOf("group") != 0) {
                this.group.setValue("");            
                this.groupList.isHide = true;
            }
        }        
    }
    get department(): AbstractControl {
        return this.filterForm.get('department');
    }
    get location(): AbstractControl {
        return this.filterForm.get('location');
    }
    get jobTitle(): AbstractControl {
        return this.filterForm.get('jobTitle');
    }
    get country(): AbstractControl {
        return this.filterForm.get('country');
    }
    get group(): AbstractControl {
        return this.filterForm.get('group');
    }
    apply() {
        this.advanceSearchCount = this.updateFilterCount();
        this.activeModal.close({
            "department": this.departmentList.selectedValue, "location": this.locationList.selectedValue,
            "group": this.groupList.selectedValue, "jobTitle": this.jobTitleList.selectedValue,
            "country": this.countryList.selectedValue, "count": this.advanceSearchCount,
            "isFilterChanged": (this.parentObject.location != this.locationList.selectedValue || this.parentObject.department != this.departmentList.selectedValue
                || this.parentObject.jobTitle != this.jobTitleList.selectedValue || this.parentObject.group != this.groupList.selectedValue ||
                this.parentObject.country != this.countryList.selectedValue) ? true : false
        });
    }
    reset() {
        this.removeParticipant("");
    }
    selectedSearch(i: number, str: string) {
        if (str.indexOf("location") == 0) {
            this.locationList.selectedValue = this.locationList.filteredList[i];
            this.locationList.isHide = true;
        } else if (str.indexOf("department") == 0) {
            this.departmentList.selectedValue = this.departmentList.filteredList[i];
            this.departmentList.isHide = true;
        } else if (str.indexOf("jobTitle") == 0) {
            this.jobTitleList.selectedValue = this.jobTitleList.filteredList[i];
            this.jobTitleList.isHide = true;
        } else if (str.indexOf("country") == 0) {
            this.countryList.selectedValue = this.countryList.filteredList[i];
            this.countryList.isHide = true;
        } else if (str.indexOf("group") == 0) {
            this.groupList.selectedValue = this.groupList.filteredList[i];
            this.groupList.isHide = true;
        }
    }
    clearSelectedItem(str: string) {
        this.removeParticipant(str);
    }
    updateFilterCount() {
        let count = 0;
        if (this.locationList.selectedValue) {
            ++count;
        }
        if (this.departmentList.selectedValue) {
            ++count;
        }
        if (this.jobTitleList.selectedValue) {
            ++count;
        }
        if (this.countryList.selectedValue) {
            ++count;
        }
        if (this.groupList.selectedValue) {
            ++count;
        }
        return count;
    }    
}