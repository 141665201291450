import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'sso-page',
    templateUrl: './sso.component.html'
})

export class SsoComponent implements OnInit {
    public url: string;

    constructor(private activatedRoute: ActivatedRoute) {
        this.url = decodeURIComponent(this.activatedRoute.snapshot.paramMap.get('ssoURL'));  
    }

    ngOnInit() {      
        if (this.url) {
            localStorage.removeItem('ssoUrl');
            window.location.href = this.url;
        }
        else {
            let response: any = {};
            response.success = false;
            response.token = null;
            response.error = 'Failed';           
            Office.context.ui.messageParent(JSON.stringify(response));
        }
    }
}    