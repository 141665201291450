import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class BaseUrlService {

    public instanseUrl: string = '';

    constructor(public router: Router) {
    }

    public checkBaseUrl() {
        let host = localStorage.getItem('host');
        let context = localStorage.getItem('context');
        if (host && context)
            this.instanseUrl = `https://${host}/${context}`
        else {
            localStorage.clear();
            this.router.navigate([`/`]);
        }
    }
}