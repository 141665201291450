import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import * as fromAction from '@store/actions/recognition.action';
import * as fromStore from '@app/store';
import { ECardService } from '@service/ecard.service';
import { Ecard, EligiblePromotion } from '@model/promotion-response.model';
import { ECardResponse } from '@model/ecard-response.model';
import { FormControl } from '@angular/forms';
import { EcardCategorySelection } from '@model/ecard-category-selection.model';
import heic2any from 'heic2any';

@Component({
    selector: 'ecards-modal',
    templateUrl: './ecards-modal.component.html',
    styleUrls: ['./ecards-modal.component.scss']
})
export class ECardsModalComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
    @Input() selectedPromotion: EligiblePromotion;
    @Output() closeModal = new EventEmitter();
    public selectedECard: ECardResponse;
    public previousECardState: ECardResponse;
    public isPreviousStateSet: boolean = false;
    public acceptableTypes: string[] = ['video/mp4', 'image/png', 'image/jpeg', 'image/gif', 'video/quicktime'];
    public fileType: string;
    public fileError: boolean = false;
    public showProgress: boolean = false;
    public progress: number = 0;
    public errorMessage: string = '';
    public imageUploadSize?: number = null;
    public videoUploadSize?: number = null;
    public allowYourCard: boolean = false;
    public selectedECards: Array<any> = [];
    searchControl = new FormControl();

    constructor(private ecardService: ECardService, private store: Store<fromStore.ProductState>, private translateService: TranslateService) { }

    ngOnInit() {
        let lastSelectedCategory = "All";
        this.selectedECards = this.selectedPromotion?.ecards;
        this.searchControl.valueChanges.subscribe(value => {
            if (value == "All") {
                this.selectedECards = this.selectedPromotion.ecards;
            }
            else {
                this.selectedECards = this.selectedPromotion.ecards.filter(x => x && x.categoryType && x.categoryType.toLowerCase() == value.toLowerCase());
            }            
            let currentEcardCategory = new EcardCategorySelection();
            currentEcardCategory.value = value;
            this.store.dispatch({ type: fromAction.UPDATE_ECARD_CATEGORY, payload: currentEcardCategory });
        });
        this.store.select(fromStore.recognitionState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            if (!this.isPreviousStateSet) {
                this.previousECardState = state.ecard;
                this.isPreviousStateSet = true;
            }
            lastSelectedCategory = state?.ecardCategorySelection?.value;
            this.selectedECard = state.ecard;
        });
        this.store.select(fromStore.selectedPromotionState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            this.imageUploadSize = state.imageUploadSize;
            this.videoUploadSize = state.videoUploadSize;
            this.allowYourCard = state.allowYourOwnCard
        });
        if (this.selectedPromotion?.showeCardsCategory && this.selectedPromotion?.eCardCategory && this.selectedPromotion.eCardCategory.length > 0)
            this.searchControl.setValue(lastSelectedCategory ? lastSelectedCategory : "All");
        // this.searchControl.setValue("All");
    }

    onFileDropped(files: any[]) {
        if (files.length > 0 && this.allowYourCard)
            this.uploadFile(files[0]);
    }

    fileBrowseHandler(files: any[]) {
        if (files.length > 0)
            this.uploadFile(files[0]);
    }

    async uploadFile(file) {
        this.fileError = false;
        this.errorMessage = '';
        this.showProgress = false;
        this.progress = 0;
        const heicAddAttr = { 'fileName': '', 'fileSize': 0, 'isHEIC': false };
        if ( /\.hei(c|f)+$/.test(file.name.toLowerCase()) ) {
            heicAddAttr.isHEIC = true;
            heicAddAttr.fileName = file.name.substring(0, file.name.lastIndexOf('.')) + '.jpeg';
            heicAddAttr.fileSize = file.size;
            try {
                this.showProgress = true;
                const convertedFile = await heic2any({
                    'blob': file,
                    'toType': 'image/jpeg'
                }) as Blob;
                const options: FilePropertyBag = { type: convertedFile.type };
                file = new File([convertedFile], heicAddAttr.fileName, options);
            }
            catch(e) {
                console.log('heic/f file conversion failed! ', e);
            }
        }
        if (!this.acceptableTypes.includes(file.type)) {
            this.fileError = true;
            this.showProgress = false;
            this.translateService.get('ECards.ErrorFileFormat').subscribe((res) => {
                this.errorMessage = res;
            });
        }
        let fileType = String(file.type);

        if (fileType.includes('image') && Number(( (heicAddAttr.isHEIC ? heicAddAttr.fileSize : file.size) / (1024 * 1024)).toFixed(2)) > this.imageUploadSize) {
            this.fileError = true;
            this.showProgress = false;
            this.translateService.get('ECards.ErrorFileSize', { size: this.imageUploadSize }).subscribe((res) => {
                this.errorMessage = res;
            });
        }
        if (fileType.includes('video') && Number((file.size / (1024 * 1024)).toFixed(2)) > this.videoUploadSize) {
            this.fileError = true;
            this.translateService.get('ECards.ErrorFileSize', { size: this.videoUploadSize }).subscribe((res) => {
                this.errorMessage = res;
            });
        }
        if (this.acceptableTypes.includes(file.type) && !this.fileError) {
            this.fileType = file.type;
            this.ecardService.uploadFile(file, heicAddAttr).subscribe((response: any) => {
                this.showProgress = true;
                if (response.status) {
                    this.progress = response.message;
                }
                else if (response.success) {
                    this.fileError = false;
                    this.showProgress = this.progress > 99 ? false : true;
                    const ecard = new ECardResponse();
                    ecard.id = null;
                    ecard.success = response.success;
                    ecard.cardName = ecard.success ? response.ownCardName : "";
                    ecard.url = ecard.success ? (response.videoUrl ? response.videoUrl : response.imageUrl) : '';
                    ecard.videoUrl = ecard.success ? (response.videoUrl ? response.imageUrl : '') : '';
                    this.store.dispatch({ type: fromAction.UPDATE_ECARD, payload: ecard });
                }
                else if (!response.success) {
                    this.fileError = true;
                }
            }, (err) => {
                console.log(err);
            });
        }
        else {
            this.fileError = true;
        }
    }

    selectECard(card: Ecard) {
        this.fileError = false;
        let ecard = new ECardResponse();
        ecard.id = card.id;
        ecard.success = true;
        ecard.url = card.largeImage;
        ecard.cardName = card.name;
        this.store.dispatch({ type: fromAction.UPDATE_ECARD, payload: ecard });
    }

    removeECard() {
        this.store.dispatch({ type: fromAction.UPDATE_ECARD, payload: null });
    }

    acceptECard() {
        this.closeModal.emit();
    }

    closeModalWindow() {
        this.store.dispatch({ type: fromAction.UPDATE_ECARD, payload: this.previousECardState });
        this.closeModal.emit();
    }

    closeToast() {
        this.errorMessage = '';
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}