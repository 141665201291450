import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'success-page',
    template: `<div></div>`
})

export class SsoSuccessComponent implements OnInit {
    public token: string;
    public culture: string;
    constructor(private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.token = this.activatedRoute.snapshot.paramMap.get('token');
        this.culture = this.activatedRoute.snapshot.paramMap.get('culture');
        let response: any = {};
        response.success = false;
        response.token = null;
        response.error = null;
        response.culture = null;
        if (this.token) {
            response.success = true;
            response.token = this.token;
            response.culture = this.culture;
        }
        else
            response.error = 'Failed';        
        Office.context.ui.messageParent(JSON.stringify(response));
    }
}