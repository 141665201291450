import { Action } from '@ngrx/store';

export const CHANGE_LANGUAGE = 'Change Language';

export class ChangeLanguage implements Action {
    readonly type = CHANGE_LANGUAGE;

    constructor(public payload: string){}
}

export type TranslateAction = ChangeLanguage