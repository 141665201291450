export class ParticipantResponse {
    participants: ParticipantDetail[]
}

export class ParticipantDetail {
    id: number;
    firstName: string;
    lastName: string;
    countryName: string;
    countryCode: string;
    countryRatio: number;
    profileUrl: string;
    jobName: string;
    departmentName: string;
    orgName: string;
    optOutAwards: boolean;
    organizationName: string;
    nodes: Node[];
    nodeId?: number;
    points?: number;
    emailAddress: string;
    lastRecognitionDays?: number | string;
    avatarUrl: string;
    type: string;
}

export class CopyOthersParticipantDetail {
    id: number;
    firstName: string;
    lastName: string;
    countryName: string;
    countryCode: string;
    countryRatio: number;
    profileUrl: string;
    jobName: string;
    departmentName: string;
    orgName: string;
    optOutAwards: boolean;
    organizationName: string;
    nodes: Node[];
    nodeId?: number;
    points?: number;
    emailAddress: string;
    lastRecognitionDays?: number | string;
    avatarUrl: string;
    type: string;
}

export class Node {
    id: number;
    name: string;
}

export class AdvanceSearch {
    constructor(_department: string , _location: string, _jobTitle: string, _country: string, _group: string, _count: number) {
        this.department = _department;
        this.location = _location;
        this.jobTitle = _jobTitle;
        this.country = _country;
        this.group = _group;
        this.count = _count;
    }

    department: string;
    location: string;
    jobTitle: string;
    country: string;
    group: string;
    count: number;
}

export class UpdateParticipantPointsCommand {
    id: number;
    points?: number;
}

export class OutlookRecipient {

    constructor(name: string, email: string) {
        this.displayName = name;
        this.emailAddress = email;
    }

    emailAddress: string;
    displayName: string;
}