import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppCodeComponent } from '@page/appcode/appcode.component';
import { LoginComponent } from '@page/login/login.component';
import { PromotionComponent } from '@page/promotion/promotion.component';
import { RecognitionComponent } from '@page/recognition/recognition.component';
import { RecognitionComponentTemp } from '@page/temp/recognition.component';
import { FinalComponent } from '@page/final/final.component';
import { SsoComponent } from '@page/sso/sso.component';
import { SsoSuccessComponent } from '@page/redirect/success.component';
import { SsoFailedComponent } from '@page/redirect/failed.component';
import { PublicRecognitionComponent } from '@page/public-recognition/public-recognition.component';
import { CustomPromoComponent } from '@page/custom-promotion/custom-promotion.component';
import { CatalogueComponent } from '@page/catalogue/catalogue.component';

const routes: Routes = [
    {
        path: '',
        component: AppCodeComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'promotion',
        component: PromotionComponent
    },
    {
        path: 'recognition',
        component: RecognitionComponentTemp
    },
    {
        path: 'final',
        component: FinalComponent
    },
    {
        path: 'sso/:ssoURL',
        component: SsoComponent
    },
    {
        path: 'success/:token/:culture',
        component: SsoSuccessComponent
    },
    {
        path: 'failed/:reason',
        component: SsoFailedComponent
    },
    {
        path: 'public/:appcode/:token/:platform',
        component: PublicRecognitionComponent
    },
    {
        path: 'custompromo/:promoID/:appcode/:token/:platform',
        component: CustomPromoComponent
    },
    {
        path: 'catalogue/:type/:appcode/:token/:platform',
        component: CatalogueComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }