import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { ParticipantDetail } from '@model/participant-response.model';
import { BaseUrlService } from './url.service';

@Injectable()
export class ParticipantService extends BaseUrlService {
    constructor(public http: HttpClient, public router: Router) {
        super(router);
    }

    getTeamMembers(promotionId: number) {
        this.checkBaseUrl();
        return this.http.post(`${this.instanseUrl}/mobileapp/teamMemberSearch.do?promotionId=${promotionId}`, null).pipe(
            map((data: any) => data.participants.map((item: any) => {
                const model = new ParticipantDetail();
                model.id = item.id;
                model.firstName = item.firstName;
                model.lastName = item.lastName;
                model.countryName = item.countryName;
                model.countryCode = item.countryCode;
                model.countryRatio = item.countryRatio;
                model.profileUrl = item.profileUrl;
                model.jobName = item.jobName;
                model.departmentName = item.departmentName;
                model.orgName = item.orgName;
                model.orgName = item.orgName;
                model.optOutAwards = item.optOutAwards;
                model.nodes = item.nodes;
                model.points = null;
                model.emailAddress = item.emailAddress;
                model.avatarUrl = item.avatarUrl ? item.avatarUrl : "";
                model.type = "team";
                return model;
            }))
        );
    }

    getRecentParticipants(promotionId: number) {
        this.checkBaseUrl();
        return this.http.post(`${this.instanseUrl}/mobileapp/mostRecentRecipientsSearch.do?promotionId=${promotionId}`, null).pipe(
            map((data: any) => data.participants.map((item: any) => {
                const model = new ParticipantDetail();
                model.id = item.id;
                model.firstName = item.firstName;
                model.lastName = item.lastName;
                model.countryName = item.countryName;
                model.countryCode = item.countryCode;
                model.countryRatio = item.countryRatio;
                model.profileUrl = item.profileUrl;
                model.jobName = item.jobName;
                model.departmentName = item.departmentName;
                model.orgName = item.orgName;
                model.optOutAwards = item.optOutAwards;
                model.organizationName = item.organizationName;
                model.nodes = item.nodes;
                model.points = null;
                model.emailAddress = item.emailAddress;
                model.avatarUrl = item.avatarUrl ? item.avatarUrl : "";
                model.type = "recent";
                return model;
            }))
        );
    }

    getRA(promotionId: number) {
        this.checkBaseUrl();
        return this.http.get(`${this.instanseUrl}/mobileapp/outlook/${promotionId}/raRecipients.action`).pipe(
            map((data: any) => data.participants.map((item: any) => {
                const model = new ParticipantDetail();
                model.id = item.id;
                model.firstName = item.firstName;
                model.lastName = item.lastName;
                model.countryName = item.countryName;
                model.countryCode = item.countryCode;
                model.countryRatio = item.countryRatio;
                model.profileUrl = item.profileUrl;
                model.jobName = item.jobName;
                model.departmentName = item.departmentName;
                model.orgName = item.orgName;
                model.optOutAwards = item.optOutAwards;
                model.organizationName = item.organizationName;
                model.nodeId = item.nodeId;
                model.points = null;
                model.emailAddress = item.emailAddress;
                model.lastRecognitionDays = item.lastRecognitionDays;
                model.avatarUrl = item.avatarUrl ? item.avatarUrl : "";
                model.type = "ra";
                return model;
            }))
        );
    }

    recipientSearch(promotionId: number, searchText: string, isInitial: boolean, advanceSearch: string, recipient : Array<any>) {
        this.checkBaseUrl();
        if (searchText.length < 3 && isInitial) {
            return [];
        } 
        return this.http.get(`${this.instanseUrl}/mobileapp/outlook/recipientSearch.action?promotionId=${promotionId}&recipientIds=${recipient}&lastName=${searchText}&firstName=${searchText}${advanceSearch}`).pipe(            
            map((data: any) => data.participants.map((item: any) => {
                const model = new ParticipantDetail();
                model.id = item.id;
                model.firstName = item.firstName;
                model.lastName = item.lastName;
                model.countryName = item.countryName;
                model.countryCode = item.countryCode;
                model.countryRatio = item.countryRatio;
                model.profileUrl = item.profileUrl;
                model.jobName = item.jobName;
                model.departmentName = item.departmentName;
                model.orgName = item.orgName;
                model.optOutAwards = item.optOutAwards;
                model.organizationName = item.organizationName;
                model.nodes = item.nodes;
                model.points = null;
                model.emailAddress = item.emailAddress;
                model.avatarUrl = item.avatarUrl ? item.avatarUrl : "";
                model.type = "search";
                return model;
            }))
        );
    }
}