<div class="pageContainer finalComponent pageWithBottomFooter">    
    <div class="finalComponentBgImageWrap">
        <img class="finalComponentBgImage" *ngIf="final.success && ecard && ecard.url" [src]="ecard.url" (load)="imageLoaded()">
    </div>
    <div class="pageContent" *ngIf="!ecard || showContent">
        <div class="animatedContent dfc aic">
            <img class="finalComponentCheckImage" src="../../../assets/checkmark--outline.svg" alt="Checkmark" *ngIf="final.success">
            <p class="finalComponentRecSentText" *ngIf="final.success">{{final.message}}</p>
            <p class="finalComponentRecSentText" *ngIf="!final.success">{{final.errorMessages}}</p>
            <button class="otlkBtn otlkBtn--secondary" (click)="sendAnother()" *ngIf="final">
                {{'FinalPage.AnotherRecognition' | translate}}
            </button>
        </div>
    </div>
    <div class="pageFooter transparentBg" *ngIf="final">
        <a class="finalComponentPopout"(click)="redirect()" style="cursor: pointer;">
            <p class="finalComponentPopoutText">{{'FinalPage.ViewPublicRecognition' | translate}} {{showPoints.clientName}} <img class="" src="../../../assets/launch.svg" alt="Launch"></p>
        </a>
    </div>
</div>