import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { RecognitionService } from '@service/recognition.service';
import { EligiblePromotion, Behaviors } from '@model/promotion-response.model';
import { ParticipantDetail } from '@model/participant-response.model';
import { Message, Toast } from '@model/message.model';
import { ECardResponse } from '@model/ecard-response.model';
import { RecognitionResponse } from '@model/recognition-response.model';
import * as fromRecognitionAction from '@store/actions/recognition.action';
import * as fromStore from '@app/store';

@Component({
    selector: 'recognition-page-temp',
    templateUrl: './recognition.component.html',
    styleUrls: ['./recognition.component.scss']
})
export class RecognitionComponentTemp implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
    public selectedParticipant: ParticipantDetail[];
    public copyOthersParticipant: any;
    public message: Message;
    public selectedBehavior: Behaviors;
    public selectedECard: ECardResponse;
    public selectedPromotion: EligiblePromotion;
    public showRATab: boolean;
    public isSearchFocus: boolean = false;
    public isParticipantModalOpen: boolean = false;
    public isMessageModalOpen: boolean = false;
    public isBehaviorModalOpen: boolean = false;
    public isECardsModalOpen: boolean = false;
    public showToast: boolean = false;
    public isSubmitted: boolean = false;
    public toast: Toast;
    public noBudget: boolean = false;

    constructor(private store: Store<fromStore.ProductState>, private router: Router,
        private recognitionService: RecognitionService) { }

    ngOnInit() {
        this.store.select(fromStore.selectedPromotionState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            this.selectedPromotion = state.promotion;
            this.showRATab = state.showRATab;
            if(this.selectedPromotion.budgetInfo == null){
                this.noBudget = true;
            }
        });
        this.store.select(fromStore.recognitionState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            this.selectedParticipant = state.participants;
            this.copyOthersParticipant = state.copyOthersParticipants;
            this.message = state.message;
            this.selectedBehavior = state.behavior;
            this.selectedECard = state.ecard;
            this.toast = state.toast;
        });
    }

    cancel() {
        this.router.navigate(['/promotion']);
    }

    focusInput(): void {
        this.isSearchFocus = !this.isSearchFocus;
    }

    openParticipantModal() {
        this.isParticipantModalOpen = true;
    }

    closeParticipantModal() {
        this.isParticipantModalOpen = false;
    }

    openMessageModal() {
        if (this.selectedParticipant.length > 0)
            this.isMessageModalOpen = true;
    }

    closeMessageModal() {
        this.isMessageModalOpen = false;
    }

    openBehaviorModal() {
        this.isBehaviorModalOpen = true;
    }

    closeBehaviorModal() {
        this.isBehaviorModalOpen = false;
    }

    openECardsModal() {
        this.isECardsModalOpen = true;
    }

    closeECardsModal() {
        this.isECardsModalOpen = false;
    }

    calculateAssignedPoints() {
        if (this.selectedParticipant.length == 0)
            return 0;

        let assignedPoints = this.selectedParticipant.map(a => Math.round(a.points * a.countryRatio)).reduce(function (a, b) {
            return a + b;
        });
        if (!assignedPoints)
            return 0;

        return assignedPoints;
    }

    calculateRemainingPoints(currentValue) {
        if (this.selectedParticipant.length == 0)
            return currentValue;

        let assignedPoints = this.selectedParticipant.map(a => Math.round(a.points * a.countryRatio)).reduce(function (a, b) {
            return a + b;
        });
        return currentValue - assignedPoints;
    }

    enableSubmit() {
        if (this.selectedParticipant.length == 0)
            return true;

        if (this.selectedPromotion.awardAvailable) {
            if (this.calculateRemainingPoints(this.selectedPromotion?.budgetInfo?.currentValue) < 0)
                return true;

            let error = false;
            this.selectedParticipant.map(p => {
                if (!error) {
                    if (!p.points) {
                        !p.points && p.optOutAwards ? error = false : error = true;
                    }
                    else {
                        if (this.selectedPromotion.awardType == 'pointsFixed') {
                            if (p.points != this.selectedPromotion.awardFixed)
                                error = true;
                        }
                        else {
                            if (p.points < this.selectedPromotion.awardMin || p.points > this.selectedPromotion.awardMax)
                                error = true;
                        }
                    }
                }
            });
            if (error)
                return error;
        }

        if (this.selectedPromotion.behaviorRequired && !this.selectedBehavior) {
            return true;
        }

        if (!this.message.comments || this.message.comments.length > 2000)
            return true;
    }

    closeToast() {
        this.store.dispatch({ type: fromRecognitionAction.UPDATE_TOAST, payload: null });
    }

    submit() {
        this.isSubmitted = true;
        let recognition = new Map<string, string>();
        recognition.set("promotionId", this.selectedPromotion.id.toString());
        recognition.set("nodeId", this.selectedPromotion.nodeId.toString());
        recognition.set("comments", this.message.comments);
        recognition.set("makeRecPrivate", this.message.privateRecognition.toString());
        recognition.set("sendCopyToManager", this.message.sendCopyToManager ? this.message.sendCopyToManager: "");
        recognition.set("sendCopyToMe", this.message.sendCopyToMe ? this.message.sendCopyToMe : "");
        recognition.set("sendCopyToOthers", this.message.sendCopyToOthers ? this.message.sendCopyToOthers : "");

        if (this.selectedECard) {
            if (this.selectedECard.id) {
                recognition.set("cardType", "card");
                recognition.set("cardId", this.selectedECard.id.toString());
            }
            else {
                recognition.set("cardType", "upload");
                recognition.set("cardUrl", this.selectedECard.videoUrl != '' ? this.selectedECard.videoUrl : this.selectedECard.url);
            }
        }
        else {
            recognition.set("cardType", "none");
        }
        if (this.selectedBehavior) {
            recognition.set("selectedBehavior", this.selectedBehavior.id);
        }
        for (let i = 0; i < this.selectedParticipant.length; i++) {
            recognition.set(`claimRecipientFormBeans[${i}].userId`, this.selectedParticipant[i].id.toString());
            if (this.selectedParticipant[i].nodeId)
                recognition.set(`claimRecipientFormBeans[${i}].nodeId`, this.selectedParticipant[i].nodeId.toString());
            else
                recognition.set(`claimRecipientFormBeans[${i}].nodeId`, this.selectedParticipant[i].nodes[0].id.toString());
            recognition.set(`claimRecipientFormBeans[${i}].countryCode`, this.selectedParticipant[i].countryCode);
            if ( !this.selectedParticipant[i].optOutAwards ) {
                recognition.set(`claimRecipientFormBeans[${i}].awardQuantity`, this.selectedParticipant[i].points ? this.selectedParticipant[i].points.toString() : "0");
            }
        }
        for (let i = 0; i < this.copyOthersParticipant.length; i++) {
            recognition.set(`claimCopyOthersFormBeans[${i}].id`, this.copyOthersParticipant[i].id ?this.copyOthersParticipant[i].id.toString() : "");
            
            if ( this.copyOthersParticipant[i].firstName ) {
                recognition.set(`claimCopyOthersFormBeans[${i}].firstName`, this.copyOthersParticipant[i]?.firstName ? this.copyOthersParticipant[i]?.firstName.toString() : "");
            }
            if ( this.copyOthersParticipant[i].lastName ) {
                recognition.set(`claimCopyOthersFormBeans[${i}].lastName`, this.copyOthersParticipant[i]?.lastName ?this.copyOthersParticipant[i]?.lastName.toString(): "");
            }
            if ( this.copyOthersParticipant[i].email ) {
                recognition.set(`claimCopyOthersFormBeans[${i}].email`, this.copyOthersParticipant[i]?.email ? this.copyOthersParticipant[i]?.email?.toString(): "");
            }
            if ( this.copyOthersParticipant[i].countryCode ) {
                recognition.set(`claimCopyOthersFormBeans[${i}].countryCode`, this.copyOthersParticipant[i]?.countryCode ?this.copyOthersParticipant[i]?.countryCode.toString(): "");
            }
            if ( this.copyOthersParticipant[i].countryName ) {
                recognition.set(`claimCopyOthersFormBeans[${i}].countryName`, this.copyOthersParticipant[i]?.countryName ?this.copyOthersParticipant[i]?.countryName.toString(): "");
            }
            if ( this.copyOthersParticipant[i].jobName ) {
                recognition.set(`claimCopyOthersFormBeans[${i}].jobName`, this.copyOthersParticipant[i]?.jobName ? this.copyOthersParticipant[i]?.jobName.toString(): "");
            }
            if ( this.copyOthersParticipant[i].departmentName ) {
                recognition.set(`claimCopyOthersFormBeans[${i}].departmentName`, this.copyOthersParticipant[i]?.departmentName ? this.copyOthersParticipant[i]?.departmentName.toString(): "");
            }
            if ( this.copyOthersParticipant[i].orgName ) {
                recognition.set(`claimCopyOthersFormBeans[${i}].orgName`, this.copyOthersParticipant[i]?.orgName ? this.copyOthersParticipant[i]?.orgName.toString(): "");
            }
        }
        this.recognitionService.submitRecognition(recognition).subscribe((response: RecognitionResponse) => {
            this.isSubmitted = false;
            this.store.dispatch({ type: fromRecognitionAction.UPDATE_FINAL_MESSAGE, payload: response });
            this.router.navigate(['/final']);
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}