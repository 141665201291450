import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { LoginResponse, SsoConfiguration } from '@model/login-response.model';
import { environment } from '@env/environment';
import { BaseUrlService } from './url.service';

@Injectable()
export class AuthService extends BaseUrlService {
    constructor(public http: HttpClient, public router: Router) {
        super(router);
    }

    getInstanceUrl(appcode: string) {
        return this.http.get(`${environment.beaconUrl}/recognitionApp_redirect/something.do?appCode=${appcode}`).pipe(map((response: any) => {
            if (response.appProperties.host && response.appProperties.context) {
                localStorage.setItem('host', response.appProperties.host);
                localStorage.setItem('context', response.appProperties.context);
                localStorage.setItem('appcode', appcode);
                return true;
            }
            else
                return false;
        }));
    }

    fetchInstanceUrl(appcode: string) {
        return this.http.get<any>(`${environment.beaconUrl}/recognitionApp_redirect/something.do?appCode=${appcode}`).pipe(map((response: any) => {
            return response;
        }));
    }

    getSupportEmail(domain: string) {
        return this.http.get(`${environment.beaconUrl}/recognitionApp_redirect/getContactUsEmail.action?domain=${domain}`).pipe(map((response: any) => {
            if (response.appProperties.contactUSEmail) {
                localStorage.setItem('currentDomain', response.appProperties.contactUSEmail);
                return response.appProperties.contactUSEmail;
            }
        }));
    }

    getSsoConfiguration() {
        this.checkBaseUrl();
        let headers = this.buildHttpHeader();
        return this.http.get(`${this.instanseUrl}/outlookSSOConfig.do?method=getOutlookSSOConfig`, { headers: headers }).pipe(map((response: SsoConfiguration) => {
            return response;
        }));
    }

    login(username: string, password: string) {
        this.checkBaseUrl();
        let password64 = btoa(password);
        let headers = this.buildHttpHeader();
        let postData = { "j_username": username, "j_password": password64 };

        return this.http.post(`${this.instanseUrl}/j_acegi_security_check.do?source=mobile`, postData, { headers: headers }).pipe(map((response: LoginResponse) => {
            if (response.messages && response.messages.length > 0)
                return response.messages[0];
            else
                throw new Error();
        }));
    }

    logout() {
        localStorage.removeItem('token');
        this.router.navigate(['/']);
    }

    clearLocalStorage() {
        localStorage.clear();
        this.router.navigate(['/']);
    }

    refreshToken(refresh_token) {
        this.checkBaseUrl();
        let headers = this.buildHttpHeader();
        headers = headers.set('outlookauth', refresh_token);

        return this.http.get(`${this.instanseUrl}/outlook/refreshToken.action`, { headers: headers }).pipe(map((response: any) => {
            return response.token;
        }));
    }

    private buildHttpHeader(): HttpHeaders {
        let headers = new HttpHeaders();
        headers = headers.set('content-type', 'application/json');
        headers = headers.set('originated', 'outlook');
        headers = headers.set('post-type', 'ajax');
        return headers;
    }
}