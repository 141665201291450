import * as fromPromotion from '../actions/promotion.action';
import { EligiblePromotion } from '@model/promotion-response.model'

export interface PromotionState {
    promotion?: EligiblePromotion
    showRATab: boolean
    imageUploadSize?: number
    videoUploadSize?: number
    allowYourOwnCard: boolean
}

export const initialState: PromotionState = {
    promotion: null,
    showRATab: false,
    imageUploadSize: null,
    videoUploadSize: null,
    allowYourOwnCard: false
}

export function reducer(state = initialState, action: fromPromotion.PromotionAction): PromotionState {

    switch (action.type) {
        case fromPromotion.SELECTED_PROMOTION: {
            return Object.assign({}, state, { promotion: action.payload })
        }
        case fromPromotion.UNSELECTED_PROMOTION: {
            return Object.assign({}, state, { promotion: null })
        }
        case fromPromotion.CHANGE_RTA_STATUS: {
            return Object.assign({}, state, { showRATab: action.payload })
        }
        case fromPromotion.CHANGE_FILE_UPLOAD_SIZE: {
            return Object.assign({}, state, { videoUploadSize: action.payload.video, imageUploadSize: action.payload.image })
        }
        case fromPromotion.ALLOW_OWN_CARD: {
            return Object.assign({}, state, { allowYourOwnCard: action.payload })
        }
    }

    return state;
}

export const getPromotion = (state: PromotionState) => state.promotion;
export const getRATab = (state: PromotionState) => state.showRATab;