<div class="otlkPopupHeader">
    <div class="otlkPopupHeaderInner dfr jcsb aic">
        <div>
            <button class="otlkBtn otlkBtn--tertiary cp" (click)="closeModalWindow()">
                {{'Behavior.Cancel' | translate}}
            </button>
        </div>
        <div>
            <span class="fwb">{{'Behavior.Behavior' | translate}}</span>
        </div>        
    </div>
</div>

<div class="otlkPopupContent flexGrow">
    <div class="behaviorSectionWrap">        
        <ul class="behaviorSectionList">
            <li class="behaviorSectionListItem dfr" *ngFor="let behavior of selectedPromotion.behaviors" (click)="selectBehavior(behavior)">
                <div class="mr5" *ngIf="behavior.img">
                    <img class="behaviorSectionListAvatar" [src]="behavior.img" alt="Badge Avatar">
                </div>
                <div class="behaviorSectionListContent flexGrow" [ngClass]="{'hasBehaviorImage': behavior.img}">
                    <p>
                        {{behavior.name}}
                    </p>
                </div>
            </li>            
        </ul>
    </div>
</div>