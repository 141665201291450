import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'miniloader',
    templateUrl: './loader.component.html'
})

export class MiniLoaderComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}