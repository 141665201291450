import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'failed-page',
    template: `<div></div>`
})

export class SsoFailedComponent implements OnInit {
    public reason: string;
    constructor(private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.reason = this.activatedRoute.snapshot.paramMap.get('reason');
        let response: any = {};
        response.success = false;
        response.token = null;
        if (this.reason)
            response.error = this.reason;
        else
            response.error = 'Failed';        
        Office.context.ui.messageParent(JSON.stringify(response));
    }
}