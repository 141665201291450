<div class="pageContainer pageWithBottomFooter" [ngClass]="{'noBorder': isAnyPopupOpen, 'ovh': isAnyPopupOpen}">
    <!-- Below is the toast to show when displaying errors. This should be added as a direct child to "pageContainer" div -->
    <div class="otlkToast error" *ngIf="isToastOpen">
        <div class="otlkToastInner">
            <div class="otlkToastClose">
                <i class="gg-close otlkToastCloseIcon"></i>
            </div>
            <div class="otlkToastMessage">You have entered an invalid point value.</div>
            <div class="otlkToastCloseAction">
                <i class="gg-close otlkToastCloseActionIcon" (click)="showToast()"></i>
            </div>
        </div>
    </div>    

    <div class="pageHeader">
        <h3>Promotion Name</h3>
        <!-- <button type="button" class="btn btn-outline-secondary mr-2" placement="top"
                ngbPopover="This is simple example form ItSolutionStuff.com" popoverTitle="Popover on top">
        Popover on top
        </button> -->

        <!-- <h3>{{selectedPromotion.name}}</h3> -->
    </div>
    <div class="pageContent recogSectionsPage">
        <ul class="recogSectionsList">
            <li class="recogSectionsListItem" (click)="openModal( 'isToPopupOpen' )">
                <div class="recogSectionsListItemRow">
                    <div class="recogSectionsListItemColumnLeft flexGrow">
                        <div class="recogSectionsListItemColumnRow">
                            <span class="recogSectionsListItemTitle">
                                To
                            </span>
                            <!-- Add classes "mw50" and "ellipsis" to below span if the name needs to be trimmed after some width -->
                            <span class="otlkBadge dib ml5">John Franklin</span>
                            <span class="dib othersCount">+ 10 others</span>
                        </div>
                    </div>
                    <div class="recogSectionsListItemColumnRight">
                        <i class="recogSectionsListIcon gg-chevron-right"></i>
                    </div>
                </div>
            </li>
            <li class="recogSectionsListItem" (click)="openModal( 'isMessagePopupOpen' )">
                <div class="recogSectionsListItemRow">
                    <div class="recogSectionsListItemColumnLeft flexGrow">
                        <div class="recogSectionsListItemColumnRow">
                            <span class="recogSectionsListItemTitle">
                                Message & Awards
                            </span>
                        </div>
                        <div class="recogSectionsListItemColumnRow mt5">
                            <p class="recogSectionsListItemExtraInfo">
                                Thank you for constantly going above and beyond to improve our client…
                            </p>
                        </div>
                        <div class="recogSectionsListItemColumnRow mt5">
                            <p class="recogSectionsListItemExtraInfo recogSectionsListItemExtraInfo--small">
                                20 Points Awarded / 2,280 Points Remaining
                            </p>
                        </div>
                    </div>
                    <div class="recogSectionsListItemColumnRight">
                        <i class="recogSectionsListIcon gg-chevron-right"></i>
                    </div>
                </div>
            </li>
            <li class="recogSectionsListItem" (click)="openModal( 'isBehaviorPopupOpen' )">
                <div class="recogSectionsListItemRow">
                    <div class="recogSectionsListItemColumnLeft flexGrow">
                        <div class="recogSectionsListItemColumnRow">
                            <span class="recogSectionsListItemTitle">
                                Behavior (Optional)
                            </span>
                        </div>
                        <div class="recogSectionsListItemColumnRow mt5">
                            <p class="recogSectionsListItemExtraInfo">
                                Change is inevitable… so get on with it
                            </p>
                        </div>
                    </div>
                    <div class="recogSectionsListItemColumnRight">
                        <i class="recogSectionsListIcon gg-chevron-right"></i>
                    </div>
                </div>
            </li>
            <li class="recogSectionsListItem" (click)="openModal( 'isVideoPopupOpen' )">
                <div class="recogSectionsListItemRow">
                    <div class="recogSectionsListItemColumnLeft flexGrow">
                        <div class="recogSectionsListItemColumnRow">
                            <span class="recogSectionsListItemTitle">
                                Photo / Video (Optional)
                            </span>
                        </div>
                        <div class="recogSectionsListItemColumnRow recogSectionsListItemColumnRow--flexInverse mt5">
                            <div class="recogSectionsListItemThumbnail">
                                <img src="https://picsum.photos/57" alt="Thumbnail">
                            </div>
                            <div class="recogSectionsListItemThumbnail">
                                <img src="https://picsum.photos/57" alt="Thumbnail">
                            </div>
                        </div>
                    </div>
                    <div class="recogSectionsListItemColumnRight">
                        <i class="recogSectionsListIcon gg-chevron-right"></i>
                    </div>
                </div>
            </li>
        </ul>

    </div>
    <div class="pageFooter">
        <div class="recogSectionsButtonsHolder">
            <button class="otlkBtn otlkBtn--secondary otlkBtn--inverse mr10 flexGrow" (click)="cancel()">
                Cancel
            </button>
            <button class="otlkBtn otlkBtn--primary flexGrow" disabled="disabled">
                Send
            </button>
        </div>
    </div>

    <!-- This is the popup div for "To" section. Needs to be shown / hidden based on need -->
    <!-- Adding hide class to have it hidden by default -->
    <div class="otlkPopup dfc hide" [ngClass]="{'popupHide': !isToPopupOpen, 'popupShow': isToPopupOpen}">

        <div class="otlkPopupHeader">
            <div class="otlkPopupHeaderInner dfr jcsb aic">
                <div>
                    <button class="otlkBtn otlkBtn--tertiary cp" (click)="openModal( 'isToPopupOpen' )">
                        Cancel
                    </button>
                </div>
                <div>
                    <span class="fwb">To</span>
                </div>               
            </div>
        </div>

        <div class="otlkPopupContent flexGrow">

            <div class="searchWrap">

                <!-- Add "searchWrapSearch--focus" class to below div when on focus to bring up the blue border color -->
                <div class="searchWrapSearch dfr" [ngClass]="{'searchWrapSearch--focus': isSearchFocus}">
                    <!-- Below span should be hidden when the input box below the span is focussed -->
                    <span class="searchWrapSearchIconHolder" *ngIf="!isSearchFocus">
                        <i class="gg-search searchWrapSearchIcon"></i>
                    </span>

                    <!-- Below spans are the user name tags. These should be added for each user -->
                    <span class="otlkBadge otlkBadgeWithClose dib ml5">
                        <span>John Franklin </span>
                        <span class="otlkBadgeClose"><i class="gg-close otlkBadgeCloseIcon"></i></span>
                    </span>
                    <span class="otlkBadge otlkBadgeWithClose dib ml5">
                        <span>Jen Last </span>
                        <span class="otlkBadgeClose"><i class="gg-close otlkBadgeCloseIcon"></i></span>
                    </span>

                    <input type="text" class="searchWrapSearchInput" placeholder="Find Someone" (focus)="focusInput()" (blur)="focusInput()">
                </div>

                <!-- Below div to be shown when user searches for any participant. Simply hardcoding ngIf to false for now -->
                <div class="searchWrapResultsHolder" *ngIf="false">
                    <div class="searchWrapResults">
                        <p class="searchWrapResultsTitle">Suggested People</p>
                        <ul class="userListWrapList">
                            <!-- To be repeated for each user -->
                            <li class="userListWrapListItem" *ngFor="let participant of searchParticipants"
                            (click)="selectParticipant(participant)">
                            <div class="userListWrapListItemAvatar" [ngbPopover]="suggestContent"
                                triggers="mouseenter:mouseleave" placement="right" container="body">
                                <!-- Either image or User Initials : Hide the other one -->
                                <img *ngIf="participant.avatarUrl && participant.avatarUrl!=''"
                                    [src]="participant.avatarUrl" alt="Avatar" style="width: 100%;">
                                <span *ngIf="!participant.avatarUrl"
                                    class="userListWrapListItemAvatarInitals">{{getShortName(participant.firstName, participant.lastName)}}</span>
                                <!-- <span class="userListWrapListItemAvatarInitals">JL</span> -->

                                <ng-template #suggestContent>
                                    <p class="userListWrapListItemDept userListWrapListItemInfo breakWord">
                                        <span class="userListWrapListItemInfo breakWord">{{participant.orgName}}, {{participant.countryName}}</span>
                                    </p>
                                    <p class="userListWrapListItemDept userListWrapListItemInfo">
                                        <span class="userListWrapListItemInfo">{{participant.departmentName}}, {{participant.jobName}} </span>
                                    </p>
                                </ng-template>
                            </div>
                            <div class="userListWrapListItemContent">
                                <p class="userListWrapListItemUser">{{participant.firstName}} {{participant.lastName}}
                                </p>
                                <p class="userListWrapListItemEmail userListWrapListItemInfo">
                                    {{participant.emailAddress}}</p>
                            </div>
                        </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="userListWrap">
                <p class="userListWrapHeading">Recent & Team:</p>
                <ul class="userListWrapList">
                    <!-- To be repeated for each user -->
                    <li class="userListWrapListItem">
                        <div class="userListWrapListItemAvatar">
                            <!-- Either image or User Initials : Hide the other one -->
                            <img src="https://picsum.photos/42" alt="Avatar">
                            <!-- <span class="userListWrapListItemAvatarInitals">JL</span> -->
                        </div>
                        <div class="userListWrapListItemContent">
                            <p class="userListWrapListItemUser">Jen Lastings</p>
                            <p class="userListWrapListItemEmail userListWrapListItemInfo">jen.lastings@biworldwide.com</p>
                            <p class="userListWrapListItemDept userListWrapListItemInfo">
                                <span class="userListWrapListItemFlag"><img src="https://picsum.photos/18/10" alt="Flag"></span>
                                <span class="userListWrapListItemInfo">093800, Department name, Division</span>
                            </p>
                            <p class="userListWrapListItemDept userListWrapListItemInfo">
                                <span class="userListWrapListItemInfo">Job title, </span>
                                <span class="userListWrapListItemInfo">Manager name </span>
                            </p>
                        </div>
                    </li>
                    <li class="userListWrapListItem">
                        <div class="userListWrapListItemAvatar">
                            <!-- Either image or User Initials : Hide the other one -->
                            <!-- <img src="https://picsum.photos/42" alt="Avatar"> -->
                            <span class="userListWrapListItemAvatarInitals">JL</span>
                        </div>
                        <div class="userListWrapListItemContent">
                            <p class="userListWrapListItemUser">Virat Khan</p>
                            <p class="userListWrapListItemEmail userListWrapListItemInfo">jen.lastings@biworldwide.com</p>
                            <p class="userListWrapListItemDept userListWrapListItemInfo">
                                <span class="userListWrapListItemFlag"><img src="https://picsum.photos/18/10" alt="Flag"></span>
                                <span class="userListWrapListItemInfo">093800, Department name, Division</span>
                            </p>
                            <p class="userListWrapListItemDept userListWrapListItemInfo">
                                <span class="userListWrapListItemInfo">Job title, </span>
                                <span class="userListWrapListItemInfo">Manager name </span>
                            </p>
                        </div>
                    </li>
                </ul>
            </div>

        </div>

        <div class="otlkPopupFooter">
            <button class="otlkBtn otlkBtn--primary cp w100" disabled>
                Done Adding
            </button>
        </div>

    </div>
    <!-- To Popup Ends -->

    <!-- This is the same popup div but I'm adding it with tabbed content -->
    <div class="otlkPopup dfc" [ngClass]="{'popupHide': !isToPopupOpen, 'popupShow': isToPopupOpen}">

        <div class="otlkPopupHeader">
            <div class="otlkPopupHeaderInner dfr jcsb aic">
                <div>
                    <button class="otlkBtn otlkBtn--tertiary cp" (click)="openModal( 'isToPopupOpen' )">
                        Cancel
                    </button>
                </div>
                <div>
                    <span class="fwb">To</span>
                </div>                
            </div>
        </div>

        <div class="otlkPopupContent flexGrow">

            <div class="searchWrap">

                <!-- Add "searchWrapSearch--focus" class to below div when on focus to bring up the blue border color -->
                <div class="searchWrapSearch dfr" [ngClass]="{'searchWrapSearch--focus': isSearchFocus}">
                    <!-- Below span should be hidden when the input box below the span is focussed -->
                    <span class="searchWrapSearchIconHolder" *ngIf="!isSearchFocus">
                        <i class="gg-search searchWrapSearchIcon"></i>
                    </span>

                    <!-- Below spans are the user name tags. These should be added for each user -->
                    <span class="otlkBadge otlkBadgeWithClose dib ml5">
                        <span>John Franklin </span>
                        <span class="otlkBadgeClose"><fa-icon [icon]="faTimes"></fa-icon></span>
                    </span>
                    <span class="otlkBadge otlkBadgeWithClose dib ml5">
                        <span>Jen Last </span>
                        <span class="otlkBadgeClose"><fa-icon [icon]="faTimes"></fa-icon></span>
                    </span>

                    <input 
                        type="text" 
                        class="searchWrapSearchInput" 
                        placeholder="Find Someone" 
                        (focus)="focusInput()" 
                        (blur)="focusInput()"
                        (keyup)="onKeyup($event)" 
                         >
                </div>

                <!-- Below div to be shown when user searches for any participant. Simply hardcoding ngIf to false for now -->
                <div class="searchWrapResultsHolder" *ngIf="true">
                    <div class="searchWrapResults">
                        <p class="searchWrapResultsTitle">Suggested People</p>
                        <div class="searchWrapNoResults" *ngIf="false">No results found</div>
                        <!-- <list>
                        </list> -->
                        <ul class="userListWrapList" id="userListWrapList" (keydown)="onKeydown($event)" tabindex="0">
                            <!-- To be repeated for each user -->
                            <list-item 
                                *ngFor="let participant of searchParticipants" 
                                class="userListWrapListItemComp"
                                [participant]="participant"
                                (click)="selectParticipant(participant)"
                                (itemSelected)="selectParticipant($event)">
                            </list-item>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="otlkTabs mt15" *ngIf="false">
                <div class="otlkTabsHeader">
                    <ul class="otlkTabsList dfr">
                        <li class="otlkTabsListItem">
                            <input class="otlkTabsListItemRadio" type="radio" id="tab1" name="tabGroup1" checked>
                            <label class="otlkTabsListLabel" for="tab1">Recent & Team</label>
                            <div class="otlkTabsListContent">
                                <div class="userListWrap">
                                    <ul class="userListWrapList">
                                        <!-- To be repeated for each user -->
                                        <li class="userListWrapListItem">
                                            <div class="userListWrapListItemAvatar">
                                                <!-- Either image or User Initials : Hide the other one -->
                                                <!-- Check below for popover usage -->
                                                <img src="https://picsum.photos/42" alt="Avatar" [ngbPopover]="popContent" triggers="mouseenter:mouseleave" placement="right" container="body">
                                                <ng-template #popContent>                                                    
                                                    <p class="userListWrapListItemDept userListWrapListItemInfo">
                                                        <span class="userListWrapListItemFlag"><img src="https://picsum.photos/18/10" alt="Flag"></span>
                                                        <span class="userListWrapListItemInfo">093800, Department name, Division</span>
                                                    </p>
                                                    <p class="userListWrapListItemDept userListWrapListItemInfo">
                                                        <span class="userListWrapListItemInfo">Job title, </span>
                                                        <span class="userListWrapListItemInfo">Manager name </span>
                                                    </p>
                                                </ng-template>
                                                <!-- <span class="userListWrapListItemAvatarInitals">JL</span> -->
                                            </div>
                                            <div class="userListWrapListItemContent">
                                                <p class="userListWrapListItemUser">Jen Lastings</p>
                                                <p class="userListWrapListItemEmail userListWrapListItemInfo">jen.lastings@biworldwide.com</p>
                                            </div>
                                        </li>
                                        <li class="userListWrapListItem">
                                            <div class="userListWrapListItemAvatar">
                                                <!-- Either image or User Initials : Hide the other one -->
                                                <!-- <img src="https://picsum.photos/42" alt="Avatar"> -->
                                                <span class="userListWrapListItemAvatarInitals" [ngbPopover]="popContent1" triggers="mouseenter:mouseleave" placement="right" container="body">JL</span>
                                                <ng-template #popContent1>                                                    
                                                    <p class="userListWrapListItemDept userListWrapListItemInfo">
                                                        <span class="userListWrapListItemFlag"><img src="https://picsum.photos/18/10" alt="Flag"></span>
                                                        <span class="userListWrapListItemInfo">093805, Department name, Division</span>
                                                    </p>
                                                    <p class="userListWrapListItemDept userListWrapListItemInfo">
                                                        <span class="userListWrapListItemInfo">Job title, </span>
                                                        <span class="userListWrapListItemInfo">Manager name </span>
                                                    </p>
                                                </ng-template>
                                            </div>
                                            <div class="userListWrapListItemContent">
                                                <p class="userListWrapListItemUser">Virat Khan</p>
                                                <p class="userListWrapListItemEmail userListWrapListItemInfo">jen.lastings@biworldwide.com</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li class="otlkTabsListItem">
                            <input class="otlkTabsListItemRadio" type="radio" id="tab2" name="tabGroup1">
                            <label class="otlkTabsListLabel" for="tab2">Recommended</label>
                            <div class="otlkTabsListContent">
                                <div class="userListWrap">
                                    <ul class="userListWrapList">
                                        <!-- To be repeated for each user -->
                                        <li class="userListWrapListItem">
                                            <div class="userListWrapListItemAvatar">
                                                <!-- Either image or User Initials : Hide the other one -->
                                                <img src="https://picsum.photos/42" alt="Avatar">
                                                <!-- <span class="userListWrapListItemAvatarInitals">JL</span> -->
                                            </div>
                                            <div class="userListWrapListItemContent">
                                                <p class="userListWrapListItemUser">Jen Lastings</p>
                                                <p class="userListWrapListItemEmail userListWrapListItemInfo">jen.lastings@biworldwide.com</p>
                                                <p class="userListWrapListItemDept userListWrapListItemInfo">
                                                    <span class="userListWrapListItemInfo userListWrapListItemInfo--colorDanger">Has not been recognized  in 47 days</span>
                                                </p>
                                            </div>
                                        </li>
                                        <li class="userListWrapListItem">
                                            <div class="userListWrapListItemAvatar">
                                                <!-- Either image or User Initials : Hide the other one -->
                                                <!-- <img src="https://picsum.photos/42" alt="Avatar"> -->
                                                <span class="userListWrapListItemAvatarInitals">JL</span>
                                            </div>
                                            <div class="userListWrapListItemContent">
                                                <p class="userListWrapListItemUser">Virat Khan</p>
                                                <p class="userListWrapListItemEmail userListWrapListItemInfo">jen.lastings@biworldwide.com</p>
                                                <p class="userListWrapListItemDept userListWrapListItemInfo">
                                                    <span class="userListWrapListItemInfo userListWrapListItemInfo--colorDanger">Has not been recognized  in 44 days</span>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="otlkTabsContent">

                </div>
            </div>
            
            <div class="otlkAccordianWrap mt15">
                <div class="otlkAccordianWrapOverlay" *ngIf="false"></div>
                <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0,ngb-panel-1">
                    <ngb-panel>
                        <ng-template ngbPanelHeader let-opened="opened">
                            <i class="accordianHeaderIcon gg-chevron-right" [ngClass]="{'collapsed': !opened}"></i>  
                            <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0"><span>Recent</span></button>
                          </ng-template>
                      <ng-template ngbPanelContent>
                        <div class="userListWrap">
                            <ul class="userListWrapList">
                                <!-- To be repeated for each user -->
                                <li class="userListWrapListItem">
                                    <div class="userListWrapListItemAvatar">
                                        <!-- Either image or User Initials : Hide the other one -->
                                        <!-- Check below for popover usage -->
                                        <img src="https://picsum.photos/42" alt="Avatar" [ngbPopover]="popContent" triggers="mouseenter:mouseleave" placement="right" container="body">
                                        <ng-template #popContent>                                                    
                                            <p class="userListWrapListItemDept userListWrapListItemInfo breakWord">
                                                <span class="userListWrapListItemFlag"><img src="https://picsum.photos/18/10" alt="Flag"></span>
                                                <span class="userListWrapListItemInfo">093800, Department name, Division</span>
                                            </p>
                                            <p class="userListWrapListItemDept userListWrapListItemInfo">
                                                <span class="userListWrapListItemInfo">Job title, </span>
                                                <span class="userListWrapListItemInfo">Manager name </span>
                                            </p>
                                        </ng-template>
                                        <!-- <span class="userListWrapListItemAvatarInitals">JL</span> -->
                                    </div>
                                    <div class="userListWrapListItemContent">
                                        <p class="userListWrapListItemUser">Jen Lastings</p>
                                        <p class="userListWrapListItemEmail userListWrapListItemInfo">jen.lastings@biworldwide.com</p>
                                    </div>
                                </li>
                                <li class="userListWrapListItem">
                                    <div class="userListWrapListItemAvatar">
                                        <!-- Either image or User Initials : Hide the other one -->
                                        <!-- <img src="https://picsum.photos/42" alt="Avatar"> -->
                                        <span class="userListWrapListItemAvatarInitals" [ngbPopover]="popContent1" triggers="mouseenter:mouseleave" placement="right" container="body">JL</span>
                                        <ng-template #popContent1>                                                    
                                            <p class="userListWrapListItemDept userListWrapListItemInfo breakWord">
                                                <span class="userListWrapListItemFlag"><img src="https://picsum.photos/18/10" alt="Flag"></span>
                                                <span class="userListWrapListItemInfo breakWord">093805, Department name, Division</span>
                                            </p>
                                            <p class="userListWrapListItemDept userListWrapListItemInfo">
                                                <span class="userListWrapListItemInfo">Job title, </span>
                                                <span class="userListWrapListItemInfo">Manager name </span>
                                            </p>
                                        </ng-template>
                                    </div>
                                    <div class="userListWrapListItemContent">
                                        <p class="userListWrapListItemUser">Virat Khan</p>
                                        <p class="userListWrapListItemEmail userListWrapListItemInfo">jen.lastings@biworldwide.com</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                      </ng-template>
                    </ngb-panel>
                    <ngb-panel>
                      <ng-template ngbPanelHeader let-opened="opened">
                        <i class="accordianHeaderIcon gg-chevron-right" [ngClass]="{'collapsed': !opened}"></i>
                        <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0"><span>Team</span></button>
                      </ng-template>
                      <ng-template ngbPanelContent>
                        <div class="userListWrap">
                            <ul class="userListWrapList">
                                <!-- To be repeated for each user -->
                                <li class="userListWrapListItem">
                                    <div class="userListWrapListItemAvatar">
                                        <!-- Either image or User Initials : Hide the other one -->
                                        <img src="https://picsum.photos/42" alt="Avatar">
                                        <!-- <span class="userListWrapListItemAvatarInitals">JL</span> -->
                                    </div>
                                    <div class="userListWrapListItemContent">
                                        <p class="userListWrapListItemUser">Jen Lastings</p>
                                        <p class="userListWrapListItemEmail userListWrapListItemInfo">jen.lastings@biworldwide.com</p>
                                        <p class="userListWrapListItemDept userListWrapListItemInfo">
                                            <span class="userListWrapListItemInfo userListWrapListItemInfo--colorDanger">Has not been recognized  in 47 days</span>
                                        </p>
                                    </div>
                                </li>
                                <li class="userListWrapListItem">
                                    <div class="userListWrapListItemAvatar">
                                        <!-- Either image or User Initials : Hide the other one -->
                                        <!-- <img src="https://picsum.photos/42" alt="Avatar"> -->
                                        <span class="userListWrapListItemAvatarInitals">JL</span>
                                    </div>
                                    <div class="userListWrapListItemContent">
                                        <p class="userListWrapListItemUser">Virat Khan</p>
                                        <p class="userListWrapListItemEmail userListWrapListItemInfo">jen.lastings@biworldwide.com</p>
                                        <p class="userListWrapListItemDept userListWrapListItemInfo">
                                            <span class="userListWrapListItemInfo userListWrapListItemInfo--colorDanger">Has not been recognized  in 44 days</span>
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                      </ng-template>
                    </ngb-panel>
                  </ngb-accordion>
            </div>

        </div>

        <div class="otlkPopupFooter">
            <button class="otlkBtn otlkBtn--primary cp w100" disabled>
                Done Adding
            </button>
        </div>

    </div>
    <!-- To Popup With Tabs Ends -->

    <!-- This is the popup for "Message and Awards" -->
    <div class="otlkPopup dfc" [ngClass]="{'popupHide': !isMessagePopupOpen, 'popupShow': isMessagePopupOpen}">

        <div class="otlkPopupHeader">
            <div class="otlkPopupHeaderInner dfr jcsb aic">
                <div>
                    <button class="otlkBtn otlkBtn--tertiary cp" (click)="openModal( 'isMessagePopupOpen' )">
                        Cancel
                    </button>
                </div>
                <div>
                    <span class="fwb">Message & Awards</span>
                </div>                
            </div>
        </div>

        <div class="otlkPopupContent flexGrow">                       
            <div class="awardsSectionWrap">

                <p>Manager discretionary FY 2021</p>

                <div class="progressSlide__slider otlkError">
                    <div class="progressSlide__slider__bar" style="width: 90%">
                    </div>
                </div>

                <p class="recogSectionsListItemExtraInfo recogSectionsListItemExtraInfo--small mt5 colorGray9">
                    <span class="colorGray9 otlkError">1,320 - 20 = 2.280 Points Remaining</span>
                </p>

                <p class="mt15">Points</p>

                <div class="otlkCheckbox">
                    <input id="allRecip" type="checkbox" [checked]="isAllRecipCheckboxChecked" (change)="handleAllRecipCheckbox($event)" >
                    <label for="allRecip">Apply same points to all recipients</label>
                </div>

                <div class="otlkInputGroup mt5" *ngIf="isAllRecipCheckboxChecked">
                    <div class="otlkInputGroupInner otlkInputGroupInner--bordered otlkError dfr mw60">
                        <div class="otlkInputGroupInputHolder mw60">
                            <input class="otlkInputGroupInput noBorder" type="text" name="pointsInput" id="pointsInput">
                        </div>
                        <div class="otlkInputGroupLabelHolder dfr aic jcc flexGrow">
                            <span>Points</span>
                        </div>
                    </div>
                    <p class="mt5 otlkInputGroupHelpText otlkError">Enter a value between 5 and 100.</p>
                </div>

                <div class="selectedParticipantList" *ngIf="!isAllRecipCheckboxChecked">
                    <div class="userListWrap">
                        <ul class="userListWrapList">
                            <!-- To be repeated for each user -->
                            <li class="userListWrapListItem userListWrapListItem--asCard">
                                <div class="userListWrapListItemAvatar">
                                    <!-- Either image or User Initials : Hide the other one -->
                                    <img src="https://picsum.photos/42" alt="Avatar">
                                    <!-- <span class="userListWrapListItemAvatarInitals">JL</span> -->
                                </div>
                                <div class="userListWrapListItemContent">
                                    <p class="userListWrapListItemUser">Jen Lastings</p>
                                    <p class="userListWrapListItemEmail userListWrapListItemInfo">jen.lastings@biworldwide.com</p>
                                    <p class="userListWrapListItemDept userListWrapListItemInfo">
                                        <span class="userListWrapListItemFlag"><img src="https://picsum.photos/18/10" alt="Flag"></span>
                                        <span class="userListWrapListItemInfo">093800, Department name, Division</span>
                                    </p>
                                    <p class="userListWrapListItemDept userListWrapListItemInfo">
                                        <span class="userListWrapListItemInfo">Job title, </span>
                                        <span class="userListWrapListItemInfo">Manager name </span>
                                    </p>
                                    <div class="otlkInputGroup mt5">
                                        <div class="otlkInputGroupInner otlkInputGroupInner--bordered dfr mw80">
                                            <div class="otlkInputGroupInputHolder mw60">
                                                <input class="otlkInputGroupInput noBorder" type="text" name="pointsInput" id="pointsInput">
                                            </div>
                                            <div class="otlkInputGroupLabelHolder dfr aic jcc flexGrow">
                                                <span>Points</span>
                                            </div>
                                        </div>
                                        <p class="mt5 otlkInputGroupHelpText">Enter a value between 5 and 100.</p>
                                    </div>
                                </div>
                                <div class="userListWrapListItemClose">
                                    <i class="gg-close otlkBadgeCloseIcon"></i>
                                </div>
                            </li>
                            <li class="userListWrapListItem userListWrapListItem--asCard">
                                <div class="userListWrapListItemAvatar">
                                    <!-- Either image or User Initials : Hide the other one -->
                                    <!-- <img src="https://picsum.photos/42" alt="Avatar"> -->
                                    <span class="userListWrapListItemAvatarInitals">JL</span>
                                </div>
                                <div class="userListWrapListItemContent">
                                    <p class="userListWrapListItemUser">Virat Khan</p>
                                    <p class="userListWrapListItemEmail userListWrapListItemInfo">jen.lastings@biworldwide.com</p>
                                    <p class="userListWrapListItemDept userListWrapListItemInfo">
                                        <span class="userListWrapListItemFlag"><img src="https://picsum.photos/18/10" alt="Flag"></span>
                                        <span class="userListWrapListItemInfo">093800, Department name, Division</span>
                                    </p>
                                    <p class="userListWrapListItemDept userListWrapListItemInfo">
                                        <span class="userListWrapListItemInfo">Job title, </span>
                                        <span class="userListWrapListItemInfo">Manager name </span>
                                    </p>
                                    <div class="otlkInputGroup mt5">
                                        <div class="otlkInputGroupInner otlkInputGroupInner--bordered dfr mw80">
                                            <div class="otlkInputGroupInputHolder mw60">
                                                <input class="otlkInputGroupInput noBorder" type="text" name="pointsInput" id="pointsInput">
                                            </div>
                                            <div class="otlkInputGroupLabelHolder dfr aic jcc flexGrow">
                                                <span>Points</span>
                                            </div>
                                        </div>
                                        <p class="mt5 otlkInputGroupHelpText">Enter a value between 5 and 100.</p>
                                    </div>
                                    <div class="userListWrapListItemClose">
                                        <i class="gg-close otlkBadgeCloseIcon"></i>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="mt15">
                    <textarea name="messageText" id="messageText" class="otlkTextarea tallInput tarn" placeholder="Enter comments" ></textarea>
                    <p class="otlkTextareaHelperText">0 / 2,000</p>
                </div>

                <div class="otlkCheckbox">
                    <input id="recogPrivate" type="checkbox" checked>
                    <label for="recogPrivate">Make this recognition private</label>
                </div>

            </div>
        </div>

        <div class="otlkPopupFooter">
            <button class="otlkBtn otlkBtn--primary cp w100">
                Continue
            </button>
        </div>

    </div>
    <!-- "Message and Awards" Popup Ends -->

    <!-- This is the popup for "Behavior" -->
    <div class="otlkPopup otlkPopup--noFooter dfc" [ngClass]="{'popupHide': !isBehaviorPopupOpen, 'popupShow': isBehaviorPopupOpen}">

        <div class="otlkPopupHeader">
            <div class="otlkPopupHeaderInner dfr jcsb aic">
                <div>
                    <button class="otlkBtn otlkBtn--tertiary cp" (click)="openModal( 'isBehaviorPopupOpen' )">
                        Cancel
                    </button>
                </div>
                <div>
                    <span class="fwb">Behavior</span>
                </div>                
            </div>
        </div>

        <div class="otlkPopupContent flexGrow">                       
            <div class="behaviorSectionWrap">
                <ul class="behaviorSectionList">
                    <li class="behaviorSectionListItem dfr">
                        <div class="mr10">
                            <img class="behaviorSectionListAvatar" src="https://picsum.photos/35" alt="Badge Avatar">
                        </div>
                        <div class="behaviorSectionListContent flexGrow">
                            <p>
                                4 Lanes of Business Development
                            </p>
                        </div>
                    </li>
                    <li class="behaviorSectionListItem dfr">
                        <div class="mr10">
                            <img class="behaviorSectionListAvatar" src="https://picsum.photos/35" alt="Badge Avatar">
                        </div>
                        <div class="behaviorSectionListContent flexGrow">
                            <p>
                                Birthday and Anniversary 2020
                            </p>
                        </div>
                    </li>
                    <li class="behaviorSectionListItem dfr">
                        <div class="mr10">
                            <img class="behaviorSectionListAvatar" src="https://picsum.photos/35" alt="Badge Avatar">
                        </div>
                        <div class="behaviorSectionListContent flexGrow">
                            <p>
                                Change is inevitable… so get on with it
                            </p>
                        </div>
                    </li>
                    <li class="behaviorSectionListItem dfr">
                        <div class="mr10">
                            <img class="behaviorSectionListAvatar" src="https://picsum.photos/35" alt="Badge Avatar">
                        </div>
                        <div class="behaviorSectionListContent flexGrow">
                            <p>
                                Client visit
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- <div class="otlkPopupFooter otlkPopupFooter--hide">
            <button class="otlkBtn otlkBtn--primary cp w100">
                Save Behavior
            </button>
        </div> -->

    </div>
    <!-- "Behavior" Popup Ends -->

    <!-- This is the popup for "Video" -->
    <div class="otlkPopup dfc" [ngClass]="{'popupHide': !isVideoPopupOpen, 'popupShow': isVideoPopupOpen}">

        <div class="otlkPopupHeader">
            <div class="otlkPopupHeaderInner dfr jcsb aic">
                <div>
                    <button class="otlkBtn otlkBtn--tertiary cp" (click)="openModal( 'isVideoPopupOpen' )">
                        Cancel
                    </button>
                </div>
                <div>
                    <span class="fwb">Photo / Video</span>
                </div>                
            </div>
        </div>

        <div class="otlkPopupContent flexGrow">                       
            <div class="videoSectionWrap">  
                <div class="videoSectionDragDropWrap">
                    <div class="videoSectionDragDrop otlkError">
                        <div class="videoSectionGallImage">
                            <img src="../../../assets/galThumbnail.svg" alt="Badge Avatar">
                        </div>
                        <!-- Hide this and show the "videoSectionDragUploading" div for uploading status. Adding "isUploading" variable to show / hide -->
                        <div class="videoSectionDragInput" *ngIf="!isUploading">
                            <p>Drag & Drop Or</p>
                            <div class="videoSectionBrowseFiles">
                                <p class="videoSectionBrowseFilesText">Browse Files</p>
                                <input class="videoSectionBrowseImages" type="file" name="" id="videoSectionBrowseImages">
                            </div>
                        </div>
                        <div class="videoSectionDragUploading" *ngIf="isUploading">
                            Uploading… 34%
                        </div>
                        <!-- Hide this and show the "videoSectionDragProgress" div for uploading status. Adding "isUploading" variable to show / hide  -->
                        <div class="videoSectionDragFormats" *ngIf="!isUploading">
                            <p class="videoSectionDragFormatsText">JPG, PNG, MP4, MOV</p>
                            <p class="videoSectionDragFormatsText">Maximum 100mb</p>
                        </div>
                        <div class="videoSectionDragProgress" *ngIf="isUploading">
                            <p class="videoSectionDragFormatsText fwb">img_12.png</p>
                            <div class="progressSlide__slider">
                                <div class="progressSlide__slider__bar" style="width: 90%">
                                </div>
                            </div>            
                        </div>
                        <!-- Show / hide below div based on image uploaded state -->
                        <div class="videoSectionDragPreview" *ngIf="true">
                            <div class="videoSectionDragPreviewCloseWrap">
                                <div class="videoSectionDragPreviewImgWrap">
                                    <img src="https://picsum.photos/350/300" alt="">
                                </div>
                                <div class="videoSectionDragPreviewClose">
                                    <i class="gg-close otlkBadgeCloseIcon"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="videoSectionEcardWrap">
                    <p>Or select an eCard</p>
                    <div class="videoSectionEcardImage mt10">
                        <!-- Add "videoSectionEcardImageTile--selected" class to below div for selected state -->
                        <div class="videoSectionEcardImageTile videoSectionEcardImageTile--selected">
                            <img src="https://picsum.photos/130/130" alt="Ecard">
                            <i class="gg-check-o videoSectionEcardImageTileCheckmark"></i>
                        </div>
                        <div class="videoSectionEcardImageTile">
                            <img src="https://picsum.photos/130/130" alt="Ecard">
                        </div>
                        <div class="videoSectionEcardImageTile">
                            <img src="https://picsum.photos/130/130" alt="Ecard">
                        </div>
                        <div class="videoSectionEcardImageTile">
                            <img src="https://picsum.photos/130/130" alt="Ecard">
                        </div>
                        <div class="videoSectionEcardImageTile">
                            <img src="https://picsum.photos/130/130" alt="Ecard">
                        </div>
                        <div class="videoSectionEcardImageTile">
                            <img src="https://picsum.photos/130/130" alt="Ecard">
                        </div>
                        <div class="videoSectionEcardImageTile">
                            <img src="https://picsum.photos/130/130" alt="Ecard">
                        </div>
                        <div class="videoSectionEcardImageTile">
                            <img src="https://picsum.photos/130/130" alt="Ecard">
                        </div>
                        <div class="videoSectionEcardImageTile">
                            <img src="https://picsum.photos/130/130" alt="Ecard">
                        </div>
                        <div class="videoSectionEcardImageTile">
                            <img src="https://picsum.photos/130/130" alt="Ecard">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="otlkPopupFooter">
            <button class="otlkBtn otlkBtn--primary cp w100">
                Save Image
            </button>
        </div>

    </div>
    <!-- "Video" Popup Ends -->

</div>