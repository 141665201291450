import { Component, OnInit } from '@angular/core';
import { PromotionService } from '@service/promotion.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/services/auth.service';

@Component({
    selector: 'public-recognition',
    template: '<div class="otlkRedirection">Redirecting...</div>'
})

export class PublicRecognitionComponent implements OnInit {
    public appcode: string = '';
    public platform: string = '';
    public token: string = '';
    constructor(private promotionService: PromotionService, private authService: AuthService,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit() {
        localStorage.removeItem('public');
        this.redirect();
    }

    redirect() {
        this.appcode = this.activatedRoute.snapshot?.params?.appcode;
        this.token = this.activatedRoute.snapshot?.params?.token;
        this.platform = this.activatedRoute.snapshot?.params?.platform;
        localStorage.setItem('token', this.token);
        this.authService.fetchInstanceUrl(this.appcode).subscribe((response: any) => {
            localStorage.setItem('host', response.appProperties.host);
            localStorage.setItem('context', response.appProperties.context);
            localStorage.setItem('appcode', this.appcode);
            this.promotionService.getRedirectUrl().subscribe((url: string) => {
                if (this.platform == 'OfficeOnline')
                    localStorage.clear();
                let auth = this.getUrlParameter(url, 'auth');
                if (!auth)
                    return;

                let uri = new URL(url);
                if (document.getElementById('dynForm') != null)
                    document.getElementById('dynForm').remove();

                console.log(auth);
                let form = document.createElement('form');
                form.setAttribute('id', 'dynForm');
                //form.setAttribute('target', '_blank');
                form.setAttribute('action', `${uri.origin}${uri.pathname}`);
                form.setAttribute('method', 'post');

                let input = document.createElement('input');
                input.setAttribute('type', 'hidden');
                input.setAttribute('name', 'auth');
                input.setAttribute('value', auth);

                form.appendChild(input);
                document.body.appendChild(form);
                form.submit();
                //Office.context.ui.messageParent('');
            }, error => {
                Office.context.ui.messageParent('');
            });
        }, (err) => {
            Office.context.ui.messageParent('');
        })
    }

    getUrlParameter(url, name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(url);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };
}