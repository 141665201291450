<div class="pageContainer" [ngClass]="{'pageWithBottomFooter pageModified': showPoints}"
style="overflow: hidden">
    <!-- Below is the toast to show when displaying errors. This should be added as a direct child to "pageContainer" div -->
    <loader *ngIf="loading"></loader>
    <div class="pageHeader">
        <h3>{{'Promotions.SendRecognition' | translate}}</h3>
    </div>
    <div class="pageContent">
        <!-- Commenting the following recipients list right now. CAn include later point in time if required -->
        <!--div *ngIf="toRecipients.length>0" style="margin-bottom: 12px;">
            {{'Promotions.Recognize' | translate}} <span class="fwb"
                >{{toRecipients[0].displayName}}</span><span *ngIf="toRecipients.length>1">
                <span *ngIf="uniqueRecipient(toRecipients) > 0"> {{'Promotions.And' | translate}} <span
                        class="fwb">{{uniqueRecipient(toRecipients)}} {{'Promotions.Others' | translate}}
                    </span></span></span>
            {{'Promotions.SelectPromotionBelow' | translate}}.
        </div-->
        <div style="clear: both;"></div>
        <!-- Below card div needs to be looped from API data -->
        <ng-container *ngIf="promotions">
            <div class="card"
                (click)="promotion.customPromotion ? customPromoRedirect(promotion.id) : recognition(promotion)"
                *ngFor="let promotion of promotions.eligibleRecognitionPromotions">
                <div class="cardTitle">
                    {{promotion.name}}
                </div>
                <span *ngIf="promotion.hasMultipleOrgBasedBudgets"
                    style="font-size: 10px;">{{promotion.nodeName}}</span>
                <div class="cardContent">
                    <div class="recogInfo">
                        <div *ngIf="!promotion.awardAvailable">
                            <p class="recogInfo__Paragraph">{{'Promotions.TotalSent' | translate}}:
                                {{promotion.totalSent}}</p>
                            <p class="recogInfo__Paragraph">
                                <span *ngIf="promotion.lastSent != -1">
                                    {{'Promotions.LastSent' | translate}}: {{lastSent(promotion.lastSent) |
                                    date:'MM/dd/yyyy'}}
                                </span>
                                <span *ngIf="promotion.lastSent == -1">
                                    {{'Promotions.LastSent' | translate}}: {{'Promotions.NA' | translate}}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="recogProgress" *ngIf="promotion.awardAvailable && promotion.budgetInfo">
                        <div class="flexGrow">
                            <span class="progressSlide__pointscont progressSlide__pointscont--narrow dib">
                                <span class="progressSlide__pointsrem">
                                    {{promotion.budgetInfo.currentValue}}
                                </span>
                                <span>
                                    {{'Promotions.PointsRemaining' | translate}}
                                </span>
                            </span>
                            <span class="progressSlide__prog progressSlide__prog--wide pt5"
                                *ngIf="promotion.budgetInfo.daysRemaining>=0">
                                <fa-icon [icon]="faHourglass"></fa-icon> {{promotion.budgetInfo.daysRemaining}}
                                {{'Promotions.Days' | translate}}
                            </span>
                            <span class="progressSlide__prog progressSlide__prog--wide pt5"
                                *ngIf="promotion.budgetInfo.daysRemaining<0">
                                {{'Promotions.NoEndDate' | translate}}
                            </span>
                        </div>
                    </div>
                    <div class="recogProgress" *ngFor="let badgeDetail of promotion.badgeDetails">
                        <div class="progressAvatar">
                            <img class="progressAvatar__image" src="{{badgeDetail.img}}" alt="Sample Image">
                        </div>
                        <div class="progressSlide">
                            <div>
                                <span class="dib ellipsis progressSlide__name">{{badgeDetail.name}}</span>
                                <span class="progressSlide__prog">{{badgeDetail.progressNumerator}} /
                                    {{badgeDetail.progressDenominator}}</span>
                            </div>
                            <div class="progressSlide__slider">
                                <div class="progressSlide__slider__bar"
                                    [style.width.%]="(badgeDetail.progressNumerator*100)/badgeDetail.progressDenominator">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- <p class="assistanceText mt5" [ngClass]="{'assistanceViewText': showPoints}" *ngIf="!loading">
            <span class="infoIcon">i</span>
            <span class="assistanceTextContent ml5">
                <a (click)="redirect()" style="cursor: pointer; color:#0078D7;">
                    {{'Promotions.ViewPublicRecognition' | translate}}
                    {{showPoints && showPoints.clientName}}</a>
            </span>
        </p> -->
    </div>
    <div class="pageFooter withBorder largePadding dfr" [ngClass]="{'justify-content-end': !showPoints}">
        <div class="pageFooterHalfBlock" *ngIf="showPoints">
            <p class="myPointsLabel">{{'Promotions.MyPoints' | translate}}:</p>
            <p class="myPointsPoints mt5">
                <span class="myPointsPointsInner">{{showPoints.balance}}</span>
                <span class="m15">
                    <a style="cursor: pointer;" (click)="redirect()" [title]="publicTooltip">
                        <img class="" src="../../../assets/launch1.svg" alt="Launch">
                    </a>
                </span>
            </p>
        </div>
        <div class="pageFooterHalfBlock dfr jcfe redeemMenu" *ngIf="showPoints">
            <div ngbDropdown placement="top-right" class="d-inline-block">
                <button class="btn btn-outline-primary redeemMenuButton" id="dropdownBasic2"
                    ngbDropdownToggle>{{'Promotions.Redeem' | translate}}</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="redeemMenuDropdownShowWrap">
                    <button ngbDropdownItem class="redeemMenuDropDownItem redeemBox" *ngIf="showPoints.showShopLink"
                        (click)="catelogRedirect('merchandise')">
                        <img class="redeemMenuPopoutImage" src="../../../assets/icon_MM_color.png" alt="Badge Avatar">
                        <div class="pr-2">
                            <span class="redeemMenuPopoutText redeemTitle">{{'Promotions.Merchandise' | translate}}</span>
                            <p class="redeemContent" >{{'Promotions.MerchDesc' | translate}}</p>
                        </div>
                        <span class="redeemMenuPoputLink">
                            <a>
                                <img class="" src="../../../assets/redirect.png" alt="Launch">
                            </a>
                        </span>
                    </button>
                    <button ngbDropdownItem class="redeemMenuDropDownItem" *ngIf="showPoints.showTravelLink"
                        (click)="catelogRedirect('expMarket')">
                        <img class="redeemMenuPopoutImage" src="../../../assets/icon_EM_color.png" alt="Badge Avatar">
                        <div class="pr-2">
                            <span class="redeemMenuPopoutText redeemTitle">{{'Promotions.Experiences' | translate}}</span>
                            <p class="redeemContent">{{'Promotions.BookTravel' | translate}}</p>
                        </div>
                        <span class="redeemMenuPoputLink">
                            <a>
                                <img class="" src="../../../assets/redirect.png" alt="Launch">
                            </a>
                        </span>
                    </button>
                    <button ngbDropdownItem class="redeemMenuDropDownItem" *ngIf="showPoints.showMasteryLink"
                        (click)="catelogRedirect('mastery')">
                        <img class="redeemMenuPopoutImage" src="../../../assets/Mastery.svg" alt="Badge Avatar">
                        <div class="pr-2">
                            <span class="redeemMenuPopoutText redeemTitle">{{'Promotions.Mastery' | translate}}</span>
                            <p class="redeemContent">{{'Promotions.MasteryDesc' | translate}}</p>
                        </div>
                        <span class="redeemMenuPoputLink">
                            <a>
                                <img class="" src="../../../assets/redirect.png" alt="Launch">
                            </a>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="otlFooterLogout" *ngIf="showLogout">
            <a href="javascript:void(0);" (click)="logout()" [ngbTooltip]="'Promotions.Logout' | translate"
                placement="top" container="body" aria-label="Logout">
            </a>
        </div>
    </div>
</div>