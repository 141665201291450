import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';

import { AuthService } from '@service/auth.service'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    public isRefreshing = false;
    public refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(private authService: AuthService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = localStorage.getItem('token');
        if (token) {
            request = this.addToken(request, token);
        }
        return next.handle(request).pipe(catchError(error => {           
            if (error.status === 0 && ( request.url.includes('.action') || request.url.includes('.do') )) {
                this.authService.clearLocalStorage();
            }
            else if (error instanceof HttpErrorResponse && error.status === 412) {
                if (request.url.includes('refreshToken.action'))
                    this.authService.logout();
                else
                    return this.handle403Error(request, next, error);
            } else if (error.status === 401) {
                this.authService.logout();
            }
            else {   
                console.log(error.status);
                return throwError(error);
            }
        }));
    }

    public addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'outlookauth': `${token}`,
                'originated': 'outlook',
                'post-type': 'ajax'
            }
        });
    }

    public handle403Error(request: HttpRequest<any>, next: HttpHandler, error) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            let token = localStorage.getItem('token');
            if (token) {
                return this.authService.refreshToken(token).pipe(
                    switchMap((token: any) => {
                        this.isRefreshing = false;
                        localStorage.setItem('token', token);
                        this.refreshTokenSubject.next(token);
                        return next.handle(this.addToken(request, token));
                    }), catchError(error => {
                        this.authService.logout();
                        return throwError(error);
                    }));
            }
            else {
                this.authService.logout();
                return throwError(error);
            }
        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(jwt => {
                    return next.handle(this.addToken(request, jwt));
                }));
        }
    }
}

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
];