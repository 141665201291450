import { Component, Input, Output, OnInit, OnDestroy, EventEmitter, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, switchMap, filter, startWith } from 'rxjs/operators';
import { AbstractControl, FormControl } from '@angular/forms';

import { ParticipantService } from '@service/participant.service';
import { AdvanceSearch, ParticipantDetail } from '@model/participant-response.model';
import { Toast } from '@model/message.model';
import * as fromAction from '@store/actions/recognition.action';
import * as fromStore from '@app/store';

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { FocusKeyManager } from "@angular/cdk/a11y";
import { ListItemComponent } from "../list-item/list-item.component";
import { DOWN_ARROW, ENTER } from '@angular/cdk/keycodes';
import { TranslateService } from '@ngx-translate/core';
import { ModalFilter } from '@app/models/component/modal-filter';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as fromRecognitionAction from '@store/actions/recognition.action';

@Component({
    selector: 'participant-search-modal',
    templateUrl: './participant-modal.component.html',
    styleUrls: ['participant-modal.component.scss']
})
export class ParticipantModalComponent implements OnInit, OnDestroy {
    private keyManager: FocusKeyManager<ListItemComponent>;
    @ViewChildren(ListItemComponent) listItems: QueryList<ListItemComponent>;
    searchInputName = new FormControl('');
    
    public advanceSearch: any;

    faTimes = faTimes;
    private onDestroy$ = new Subject<void>();
    public isSearchFocus: boolean = false;
    public isPopupOpen: boolean = false;
    public loaded: boolean = false;
    public recentLoaded: boolean = false;
    public raLoaded: boolean = false;
    public openAutoComplete: boolean = false;
    public isInitial: boolean = false;
    public hideSearchSuggestions: boolean = false;
    public participants: ParticipantDetail[] = [];
    public recentParticipants: ParticipantDetail[] = [];
    public raParticipants: ParticipantDetail[] = [];
    public searchParticipants: ParticipantDetail[];
    public selectedParticipants: ParticipantDetail[];
    public searchCopyothersParticipants: ParticipantDetail[];
    public copyOthersParticipants: ParticipantDetail[];
    public selectedCopyothersParticipants:ParticipantDetail[];
    public previousState: ParticipantDetail[];
    public copyOthersPreviousState: ParticipantDetail[];
    public PreviousId : Array<any>
    public isPreviousStateSet: boolean = false;
    public isPreviousIdSet: boolean = false
    public participantId : Array<any> = []
    public SelectedparticipantId : Array<any> = []
    public showSearchError = false;
    public loading = false;
    public miniLoading = false;
    public isPreviousCopyOthersStateSet: boolean = false
    @Input() promotionId: number;
    @Input() showRATab: boolean;
    @Output() closeModal = new EventEmitter();
    @Output() closecopyModal = new EventEmitter();
    @Input() copyOthers: boolean;
    @Input() recognitionComponent: boolean;
    @Input() copySearchValue: string;

    @ViewChild('searchBox') searchInput: ElementRef;
    

    constructor(private store: Store<fromStore.ProductState>, private participantService: ParticipantService,
        private translateService: TranslateService, private modal: NgbModal) { }

    ngOnInit() {
        this.store.select(fromStore.recognitionState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            this.participantId = state.participantIds;
        })
        this.paxSearchOnChanges();
        if(this.copySearchValue){
            this.searchInputName.setValue(this.copySearchValue,{onlySelf: false, emitEvent: true});      
        }
        this.store.select(fromStore.recognitionState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            this.advanceSearch = state.advanceSearch;
            this.copyOthersParticipants = state?.copyOthersParticipants
            if(!this.isPreviousCopyOthersStateSet){
                this.copyOthersPreviousState = state.copyOthersParticipants;
                this.isPreviousCopyOthersStateSet = true
                            
            }
            if(!this.isPreviousIdSet){
                this.PreviousId = state?.participantIds;
                this.isPreviousIdSet = true;
            }
            this.selectedCopyothersParticipants = state.copyOthersParticipants;
        });
        
        
        this.store.select(fromStore.getParticipant).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            if (!this.isPreviousStateSet) {
                this.previousState = state;
                this.isPreviousStateSet = true;
            }
            this.selectedParticipants = state;
            
               
        });
        if (this.promotionId) {
            this.getRecentParticipants();
        }
    }


    ngAfterViewInit() {
        this.keyManager = new FocusKeyManager(this.listItems).withWrap();
        if(this.copySearchValue){
            this.searchInput.nativeElement.focus();
        }
    }

  

    openAdvanceFilterModal() {
        const filterModal = this.modal.open(ModalFilter, { centered: true });
        filterModal.componentInstance.parentObject = this.advanceSearch;
        filterModal.result.then((result) => {
            if (typeof result == "object") {
                this.advanceSearch = result;
                const updateAdvanceSearch = new AdvanceSearch(result.department, result.location, result.jobTitle, result.country,
                    result.group, result.count);
                this.store.dispatch({ type: fromRecognitionAction.UPDATE_ADVANCE_SEARCH, payload: updateAdvanceSearch })
                if (result.isFilterChanged)
                    this.searchInputName.updateValueAndValidity({onlySelf: false, emitEvent: true})
            }
          }, (reason) => {
          });
    }

    setDefaultAdvanceSearch() {
        this.advanceSearch = {"department": "", "location": "", "jobTitle": "", "country": "", "group": "", "count": 0};
        const updateAdvanceSearch = new AdvanceSearch("", "", "", "", "", 0);
        this.store.dispatch({ type: fromRecognitionAction.UPDATE_ADVANCE_SEARCH, payload: updateAdvanceSearch });      
    }

    resetAdvanceSearch() {
        this.setDefaultAdvanceSearch();
        this.searchInputName.updateValueAndValidity({onlySelf: false, emitEvent: true})
    }

    paxSearchOnChanges(): void {               
        this.showSearchError = false;          
        this.searchInputName.valueChanges.pipe(         
            filter(searchText => {              
                this.hideSearchSuggestions = searchText.length > 2 ? true : false;
                if (!this.hideSearchSuggestions) { 
                    this.openAutoComplete = false; 
                    this.searchParticipants = [];
                } 
 
                return this.hideSearchSuggestions;   
                         
            }),  
                                
            switchMap(searchText => {  
                this.miniLoading = true;  
                const advSearchParams : string = `&department=${this.advanceSearch?.department ? encodeURIComponent(this.advanceSearch.department) : ""}&group=${this.advanceSearch?.group ? encodeURIComponent(this.advanceSearch.group) : ""}&jobTitle=${this.advanceSearch?.jobTitle ? encodeURIComponent(this.advanceSearch.jobTitle) : ""}&country=${this.advanceSearch?.country ? encodeURIComponent(this.advanceSearch.country) : ""}&location=${this.advanceSearch?.location ? encodeURIComponent(this.advanceSearch.location) : ""}`;
                                                              
                return this.participantService.recipientSearch(this.promotionId, encodeURIComponent(searchText), this.isInitial, advSearchParams, this.participantId)})).subscribe((response: ParticipantDetail[]) => {
                    this.openAutoComplete = true;
                    this.miniLoading = false;
                    this.isInitial = true;
                    this.searchParticipants = response;
                    if (this.searchParticipants.length == 0) 
                        this.showSearchError = true;                        
                    else
                        this.showSearchError = false;
            }
        );
    }
    
    getTeamParticipants() {
        this.participantService.getTeamMembers(this.promotionId).subscribe((response: ParticipantDetail[]) => {
            this.loaded = true;
            this.participants = response;
            this.initialRemoveSelectedParticiapnt();
            this.initialRemoveCopyOthersParticiapnt()
        });
    }

    getRecentParticipants() {
        this.loading = true;
        this.participantService.getRecentParticipants(this.promotionId).subscribe((response: ParticipantDetail[]) => {
            this.recentLoaded = true;
            this.recentParticipants = response;
            this.loading = false;
            this.initialRemoveSelectedParticiapnt();
            this.initialRemoveCopyOthersParticiapnt()
            this.getTeamParticipants();
            if (this.showRATab)
                this.getRA();
        }, () => {
            this.loading = false;
            this.getTeamParticipants();
            if (this.showRATab)
                this.getRA();
        });
    }

    getRA() {
        this.participantService.getRA(this.promotionId).subscribe((response: ParticipantDetail[]) => {
            this.raLoaded = true;
            this.raParticipants = response;
            this.initialRemoveSelectedParticiapnt();
            this.initialRemoveCopyOthersParticiapnt()
        });
    }
    // Commenting this search call for future reference purpose
    // recipientSearch(searchText: string) {
    //     this.showSearchError = false;         
    //     if (searchText.length > 2) {                       
    //         this.participantService.recipientSearch(this.promotionId, searchText, this.isInitial).subscribe((response: ParticipantDetail[]) => {
    //             this.openAutoComplete = true;
    //             this.searchParticipants = response;
    //             if (this.searchParticipants.length == 0)
    //                 this.showSearchError = true;
    //             else
    //                 this.showSearchError = false;
    //         });
    //     }
    //     else {
    //         this.openAutoComplete = false;
    //         this.searchParticipants = [];
    //     }
    // }

    selectParticipant(participant: ParticipantDetail) {
        if (this.openAutoComplete) {
            this.openAutoComplete = false;
            this.searchParticipants = [];
            this.searchInput.nativeElement.value = '';
        }
        let exist = this.selectedParticipants.find(x => x.id === participant.id);
        this.SelectedparticipantId.push(participant.id);
        if (!exist && this.recognitionComponent) {
            this.store.dispatch({ type: fromAction.ADD_PARTICIPANT, payload: participant });
            this.store.dispatch({ type: fromAction.PARTICIPANT_ID, payload: participant.id });
            let copyOthersExist = this.selectedCopyothersParticipants.find(x => x.id === participant.id);
            if(copyOthersExist){
                this.store.dispatch({ type: fromAction.REMOVE_COPYOTHERS_PARTICIPANT, payload: participant.id });
            }

            if (participant.type == "team") {
                let index = this.participants.findIndex(x => x.id === participant.id);
                this.participants.splice(index, 1);
            }
            else if (participant.type == "ra") {
                let index = this.raParticipants.findIndex(x => x.id === participant.id);
                this.raParticipants.splice(index, 1);
            }
            else if (participant.type == "recent") {
                let index = this.recentParticipants.findIndex(x => x.id === participant.id);
                this.recentParticipants.splice(index, 1);
            }
        }
        else if (this.copyOthers) {
            let copyOthersExist = this.selectedCopyothersParticipants.find(x => x.id === participant.id);
            if(!copyOthersExist){
            this.store.dispatch({ type: fromAction.COPYOTHERS_PARTICIPANT, payload: participant });

            if (participant.type == "team") {
                let index = this.participants.findIndex(x => x.id === participant.id);
                this.participants.splice(index, 1);
            }
            else if (participant.type == "ra") {
                let index = this.raParticipants.findIndex(x => x.id === participant.id);
                this.raParticipants.splice(index, 1);
            }
            else if (participant.type == "recent") {
                let index = this.recentParticipants.findIndex(x => x.id === participant.id);
                this.recentParticipants.splice(index, 1);
            }
        }
        else {
            let toast = new Toast();
            toast.message = this.translateService.instant('Participants.AlreadyExists');
            this.store.dispatch({ type: fromAction.UPDATE_TOAST, payload: toast });
            setTimeout(() => {
                this.store.dispatch({ type: fromAction.UPDATE_TOAST, payload: null });
            }, 3000);
        }
        }
        else {
            let toast = new Toast();
            toast.message = this.translateService.instant('Participants.AlreadyExists');
            this.store.dispatch({ type: fromAction.UPDATE_TOAST, payload: toast });
            setTimeout(() => {
                this.store.dispatch({ type: fromAction.UPDATE_TOAST, payload: null });
            }, 3000);
        }
        this.keyManager.setActiveItem(-1);
        this.searchInput.nativeElement.focus();
    }

    removeParticipant(participant: ParticipantDetail) {
        if(this.recognitionComponent){
        this.store.dispatch({ type: fromAction.REMOVE_PARTICIPANT, payload: participant.id });
        this.store.dispatch({ type: fromAction.REMOVE_PARTICIPANT_ID, payload: participant.id });
        }
        if(this.copyOthers){
            this.store.dispatch({ type: fromAction.REMOVE_COPYOTHERS_PARTICIPANT, payload: participant.id });
            this.store.dispatch({ type: fromAction.REMOVE_PARTICIPANT_ID, payload: participant.id });
        }
        if (participant.type == "team")
            this.participants.push(participant);
        else if (participant.type == "ra")
            this.raParticipants.push(participant);
        else if (participant.type == "recent")
            this.recentParticipants.push(participant);
    }

    initialRemoveSelectedParticiapnt() {
        this.selectedParticipants.map(participant => {
            if (participant.type == "team") {
                let index = this.participants.findIndex(x => x.id === participant.id);
                this.participants.splice(index, 1);
            }
            else if (participant.type == "ra") {
                let index = this.raParticipants.findIndex(x => x.id === participant.id);
                this.raParticipants.splice(index, 1);
            }
            else if (participant.type == "recent") {
                let index = this.recentParticipants.findIndex(x => x.id === participant.id);
                this.recentParticipants.splice(index, 1);
            }
        })

    }
    initialRemoveCopyOthersParticiapnt() {

        this.copyOthersParticipants.map(participant => {
            if (participant.type == "team") {
                let index = this.participants.findIndex(x => x.id === participant.id);
                this.participants.splice(index, 1);
            }
            else if (participant.type == "ra") {
                let index = this.raParticipants.findIndex(x => x.id === participant.id);
                this.raParticipants.splice(index, 1);
            }
            else if (participant.type == "recent") {
                let index = this.recentParticipants.findIndex(x => x.id === participant.id);
                this.recentParticipants.splice(index, 1);
            }
        })

    }
    saveParticipant() {
        this.closeModal.emit();
    }
    saveCopyOtherParticipant() {
        this.closecopyModal.emit();
    }

    getShortName(firstName, lastName) {
        const fullName = firstName + ' ' + lastName;
        return fullName.split(' ').map(n => n[0]).join('');
    }

    focusInput(): void {
        this.isSearchFocus = !this.isSearchFocus;
    }
    closecopyotherModal(){
        this.store.dispatch({ type: fromAction.RESTORE_COPYOTHERSPARTICIPANT_TO_PREVIOUS_STATE, payload: this.copyOthersPreviousState });
        this.closecopyModal.emit()
    }
    savecopyotherModal() {
        this.closecopyModal.emit()
    }

    closeModalWindow() {
        this.store.dispatch({ type: fromAction.RESTORE_PARTICIPANT_TO_PREVIOUS_STATE, payload: this.previousState });
        this.closeModal.emit();
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    onKeydown(event) {
        if (event.keyCode === ENTER) {
            this.selectParticipant(this.keyManager.activeItem.participant);

        } else {
            this.keyManager.onKeydown(event);
        }
    }

    onKeyup(event) {
        if (event.keyCode === DOWN_ARROW) {
            this.keyManager.setActiveItem(0);
        }
    }
}