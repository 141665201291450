export const participantsSample = {
    "participants": [
        {
            "id": 6156,
            "firstName": "Adrianna",
            "lastName": "Johnson",
            "countryName": "United States",
            "countryCode": "us",
            "countryRatio": 1.0,
            "allowPublicRecognition": true,
            "allowPublicInformation": true,
            "profileUrl": "",
            "participantUrl": "https://avidanpprd.performnet.com/avidan/publicProfileView.do?method=display&clientState=7Rwd3qIQ6dwpEaQlJ5gCLvYI6wg0c59sT%2ByTFAZMphVMU64uYN2diMWcB5dY8v%2F9Rvdx8IdhLiUV%0A%2FWESsS1D8EuRs6ZxudDQNUYjUcxbcCTSP1Jc%2FlUv%2BWfZaAu692IA0LVq%2BWe%2B4u8vAgjR%2FiOAUGXW%0APnkgXY3pDCPnWsVKW%2B2GYzmS7kgevZO31XbMsUeqECc8xmpWWT%2FmCcMCEKawaTcYEsOTIv9UQtmj%0AujIaNjQ1A2aMAdu3Oa6NZWW4AHj6LkrdljDPWvU2IH9BqW61LFRPF6Zs9mdca6LOYQ%3D%3D&cryptoPass=1",
            "jobName": "Employee",
            "departmentName": "Service",
            "orgName": "Middle Atlantic",
            "nodes": [
                {
                    "id": 3071,
                    "name": "Middle Atlantic"
                }
            ],
            "badges": [],
            "delegators": [],
            "alert": {
                "openNewWindow": false,
                "saGiftCode": false,
                "instantPoll": false,
                "isPlateauRedemption": false
            },
            "applicableSidebarModules": [],
            "urlEdit": "",
            "emailAddress": "avidantestemail@biworldwide.com",
            "organizationName": "Middle Atlantic",
            "isSelf": false,
            "largeAudience": false,
            "isThrowdownEnabled": false,
            "isSelected": false,
            "isLocked": false,
            "isPublic": true
        },
        {
            "id": 6188,
            "firstName": "Amanda",
            "lastName": "Johnson",
            "countryName": "United States",
            "countryCode": "us",
            "countryRatio": 1.0,
            "allowPublicRecognition": true,
            "allowPublicInformation": true,
            "profileUrl": "",
            "participantUrl": "https://avidanpprd.performnet.com/avidan/publicProfileView.do?method=display&clientState=x56JmTH2kRKLuFU7H6WBd3CapJupd1%2BxTRMpLGjNHiMtf4x%2Bldv%2FVya1TdKfeR6juRl0UvuUM4z8%0AObWTtZeuCVuXsEJQ5jvcmHQ5ko8VxzFnw%2FCeD6%2FWadqDwwnevAKf9iTU5ZQoiSPjaANpVqYTUSL0%0A7Kc6U4XDIYZnn4b5yO22K2fp2zSdoKX7smY%2F%2F5ZkV7dCMZ5kt%2BJ6s0CocqcM%2F77P8Fuk2CV%2BeIoQ%0AAn7TVnh6MbaOzBDV9NzkibBW5rnorh2T3B0uplqqCOuEFKrha5J2v8NzATspYI9tIA%3D%3D&cryptoPass=1",
            "jobName": "Employee",
            "departmentName": "Office",
            "orgName": "Middle Atlantic",
            "nodes": [
                {
                    "id": 3071,
                    "name": "Middle Atlantic"
                }
            ],
            "badges": [],
            "delegators": [],
            "alert": {
                "openNewWindow": false,
                "saGiftCode": false,
                "instantPoll": false,
                "isPlateauRedemption": false
            },
            "applicableSidebarModules": [],
            "urlEdit": "",
            "emailAddress": "avidantestemail@biworldwide.com",
            "organizationName": "Middle Atlantic",
            "isSelf": false,
            "largeAudience": false,
            "isThrowdownEnabled": false,
            "isSelected": false,
            "isLocked": false,
            "isPublic": true
        },
        {
            "id": 6190,
            "firstName": "Amina",
            "lastName": "Johnson",
            "countryName": "United States",
            "countryCode": "us",
            "countryRatio": 1.0,
            "allowPublicRecognition": true,
            "allowPublicInformation": true,
            "profileUrl": "",
            "participantUrl": "https://avidanpprd.performnet.com/avidan/publicProfileView.do?method=display&clientState=%2BXtC4%2F385JXLzkHGthtPj1IOYWTcAlBKw5ZC27E%2BdOcqDtsESgUj3rOGm%2BGRJ3TzAZ8XZHwO81Mh%0ARW5XLJ%2BGa6af5zQSkLNSZkm8K2zV9PYxcj%2BPPkVZXHOA44Dwl6m%2F9wPj5lRowmhUEBdj7j20KmOB%0Ac9j%2BB2UA8e5v9CJr0oZVbcpnThvgVbJEYG%2BEzOeBG6Ze%2B2wDrQwF%2BrP%2Fq%2FPQQP654FOH9tYMT3j4%0AgH9jbEaITPDAiQTZutsg0RwomGm8MsaJuQNHDa3fq%2B5voxkzs%2B1nKSvKT%2FNoP6OR7g%3D%3D&cryptoPass=1",
            "jobName": "Trainee",
            "departmentName": "Sales",
            "orgName": "East North Central",
            "nodes": [
                {
                    "id": 3072,
                    "name": "East North Central"
                }
            ],
            "badges": [],
            "delegators": [],
            "alert": {
                "openNewWindow": false,
                "saGiftCode": false,
                "instantPoll": false,
                "isPlateauRedemption": false
            },
            "applicableSidebarModules": [],
            "urlEdit": "",
            "emailAddress": "avidantestemail@biworldwide.com",
            "organizationName": "East North Central",
            "isSelf": false,
            "largeAudience": false,
            "isThrowdownEnabled": false,
            "isSelected": false,
            "isLocked": false,
            "isPublic": true
        },
        {
            "id": 6095,
            "firstName": "Baasheen",
            "lastName": "Johnson",
            "countryName": "United States",
            "countryCode": "us",
            "countryRatio": 1.0,
            "allowPublicRecognition": true,
            "allowPublicInformation": true,
            "profileUrl": "",
            "participantUrl": "https://avidanpprd.performnet.com/avidan/publicProfileView.do?method=display&clientState=KzJmm9MOEil0rZXMA4ootVxZ5GvstY0VUwE9PAC1jjNvGgZRAAtd4Ff5XXT8fI3T1QyW6U%2BfFkAx%0AMWLq2NvayZqAElosSYxKD7PNdHPGddVv7BmbW62PTM%2FiV3iRrSBIG0rqH2lfm6mAmfHxGWD7%2Fscr%0ATwBqS3zkkhV1w%2BpDLY0o03FUrHi33S4qkepBFJz3rCDIRIGGSqMeowkyA6y%2BpEEYwi9WEzwSfIaF%0ASRNFQIA2yhV3qXih4Oi0q7t64lDimpNUX4VJlM%2Fp9RUeor5YbXjtEY%2FX3htfzNXt5Q%3D%3D&cryptoPass=1",
            "jobName": "Trainee",
            "departmentName": "Office",
            "orgName": "South",
            "nodes": [
                {
                    "id": 3068,
                    "name": "South"
                }
            ],
            "badges": [],
            "delegators": [],
            "alert": {
                "openNewWindow": false,
                "saGiftCode": false,
                "instantPoll": false,
                "isPlateauRedemption": false
            },
            "applicableSidebarModules": [],
            "urlEdit": "",
            "emailAddress": "avidantestemail@biworldwide.com",
            "organizationName": "South",
            "isSelf": false,
            "largeAudience": false,
            "isThrowdownEnabled": false,
            "isSelected": false,
            "isLocked": false,
            "isPublic": true
        },
        {
            "id": 6129,
            "firstName": "Brandon",
            "lastName": "Johnson",
            "countryName": "United States",
            "countryCode": "us",
            "countryRatio": 1.0,
            "allowPublicRecognition": true,
            "allowPublicInformation": true,
            "profileUrl": "",
            "participantUrl": "https://avidanpprd.performnet.com/avidan/publicProfileView.do?method=display&clientState=jNgdfdr86Xd1uIYm5u6cWD%2B%2FhOX4OsecdwTP6%2BgVh6VELzlAvkxvBCx9ih4Rc9mzzgu4Oo9JVgSJ%0AW83z3nvg4yjESCY%2Byagj05oCqu5a1G9ui%2Be3Q5rStz%2ByNXMYqk9OL3%2Fe2hiS%2B0QhSDtMqXakwVnX%0AvWadpVPyizlJe9vNxFReBzrO4vgBUJXr2ltZ3dWPvyy6xmklGvSnBt0TLOau4NIHReU6rPibRL0N%0Alt5wz%2FV9ny3dHyQO4Uh6kEqBPFqvKezylGNgJeIp19d6RMwo%2BjSg8qO5UyAtr7WNTw%3D%3D&cryptoPass=1",
            "jobName": "Employee",
            "departmentName": "Service",
            "orgName": "Haryana",
            "nodes": [
                {
                    "id": 6002,
                    "name": "Haryana"
                }
            ],
            "badges": [],
            "delegators": [],
            "alert": {
                "openNewWindow": false,
                "saGiftCode": false,
                "instantPoll": false,
                "isPlateauRedemption": false
            },
            "applicableSidebarModules": [],
            "urlEdit": "",
            "emailAddress": "avidantestemail@biworldwide.com",
            "organizationName": "Haryana",
            "isSelf": false,
            "largeAudience": false,
            "isThrowdownEnabled": false,
            "isSelected": false,
            "isLocked": false,
            "isPublic": true
        },
        {
            "id": 6218,
            "firstName": "Dawn",
            "lastName": "Johnson",
            "countryName": "United States",
            "countryCode": "us",
            "countryRatio": 1.0,
            "allowPublicRecognition": true,
            "allowPublicInformation": true,
            "profileUrl": "",
            "participantUrl": "https://avidanpprd.performnet.com/avidan/publicProfileView.do?method=display&clientState=Ib9zAMrkqKBgTyvaI0fvrXqjRvg0A%2F9FuQiPtyoFaCXT3FaT%2FLJJpgTJ4L3tHrwwq31x4%2BUC6r4h%0AFYBRlD%2BaKaYs1a1%2Fg%2BlXpRVlucQVOwrnU82HMuTfqIEIbqpzqGYLmqjctJNOolsNZPyMPLi04m1z%0A%2Bq28aTNKIpiaRJoqNcPfhGbKvr6SMFa9h1kdZqFFHxOyfTYAkwLQz%2BdNJBaKWbuUh%2BN8xJCUSAD6%0A6%2BR49wWrYbPqdfLoLkQ7X4KQn1XDIf09fcYWPR53JUMRZw7woPBwm3FeZtvuPONvsg%3D%3D&cryptoPass=1",
            "jobName": "Employee",
            "departmentName": "Office",
            "orgName": "TamilNadu",
            "nodes": [
                {
                    "id": 6003,
                    "name": "TamilNadu"
                }
            ],
            "badges": [],
            "delegators": [],
            "alert": {
                "openNewWindow": false,
                "saGiftCode": false,
                "instantPoll": false,
                "isPlateauRedemption": false
            },
            "applicableSidebarModules": [],
            "urlEdit": "",
            "emailAddress": "avidantestemail@biworldwide.com",
            "organizationName": "TamilNadu",
            "isSelf": false,
            "largeAudience": false,
            "isThrowdownEnabled": false,
            "isSelected": false,
            "isLocked": false,
            "isPublic": true
        },
        {
            "id": 6606,
            "firstName": "Gene",
            "lastName": "Johnson",
            "countryName": "United States",
            "countryCode": "us",
            "countryRatio": 1.0,
            "allowPublicRecognition": true,
            "allowPublicInformation": true,
            "profileUrl": "",
            "participantUrl": "https://avidanpprd.performnet.com/avidan/publicProfileView.do?method=display&clientState=rXyVCCGhIhqHlSwKUmst2f635u6my06hFca3SabQHWTACp%2BYjsUksZwzDHLW%2ByM5dDQEQ3YxCUZU%0ALJPWbeWvTOhaJnfptdWrrPxWkUKJP6HGCJEQEOUgTTiHXGCqR3%2BAWlSur1wzZHEk8K9%2BayRaeDoz%0Akfva165U9%2FJgz9Mofqkcevz%2B38Us43SR0MmJXdw4m11R16eQqfcTOa%2FfOCmL7u%2FPsBFFs7Iykup%2B%0A1UTDu5qW0DFq%2BxXsWs8TPnBHjOhxQQWHuLR8mAkSZhCu9p9x3YmC1JcthFeX6RlLgA%3D%3D&cryptoPass=1",
            "jobName": "Employee",
            "departmentName": "Service",
            "orgName": "District of Columbia",
            "nodes": [
                {
                    "id": 3087,
                    "name": "District of Columbia"
                }
            ],
            "badges": [],
            "delegators": [],
            "alert": {
                "openNewWindow": false,
                "saGiftCode": false,
                "instantPoll": false,
                "isPlateauRedemption": false
            },
            "applicableSidebarModules": [],
            "urlEdit": "",
            "emailAddress": "avidantestemail@biworldwide.com",
            "organizationName": "District of Columbia",
            "isSelf": false,
            "largeAudience": false,
            "isThrowdownEnabled": false,
            "isSelected": false,
            "isLocked": false,
            "isPublic": true
        },
        {
            "id": 6607,
            "firstName": "Genever",
            "lastName": "Johnson",
            "countryName": "United States",
            "countryCode": "us",
            "countryRatio": 1.0,
            "allowPublicRecognition": true,
            "allowPublicInformation": true,
            "profileUrl": "",
            "participantUrl": "https://avidanpprd.performnet.com/avidan/publicProfileView.do?method=display&clientState=6%2FU9IrruF1xVNdd3o0ZXsk4ImfQdcw1G3cOYM2KD1l%2Bc9SVFs4mUNvryxl8ukHvoc0hlPrwS0P9p%0ANjoQnebxwenfTT32ARp6V%2F7aa6liWxX6vXWYgliUABLs4hlYS34%2BHy4xZXFsYX44MbdZgFMzIr95%0A5MpNu%2BpaXRSSGhbyKbUDQVAf2bz43Xv4vAkHHSY27yM6UFFvYgjuykr4XTs45cDq3ETpFX%2FVRFQB%0Anap7fF5nfzXccfGjpsX9MyMf8Cmmdk2eYWD%2Fpaaa17f4zukvj%2BtZ7JuJ0hFNQn8m6A%3D%3D&cryptoPass=1",
            "jobName": "Manager",
            "departmentName": "Sales",
            "orgName": "Delhi",
            "nodes": [
                {
                    "id": 6007,
                    "name": "Delhi"
                }
            ],
            "badges": [],
            "delegators": [],
            "alert": {
                "openNewWindow": false,
                "saGiftCode": false,
                "instantPoll": false,
                "isPlateauRedemption": false
            },
            "applicableSidebarModules": [],
            "urlEdit": "",
            "emailAddress": "avidantestemail@biworldwide.com",
            "organizationName": "Delhi",
            "isSelf": false,
            "largeAudience": false,
            "isThrowdownEnabled": false,
            "isSelected": false,
            "isLocked": false,
            "isPublic": true
        },
        {
            "id": 6528,
            "firstName": "Jeremy",
            "lastName": "Johnson",
            "countryName": "United States",
            "countryCode": "us",
            "countryRatio": 1.0,
            "allowPublicRecognition": true,
            "allowPublicInformation": true,
            "profileUrl": "",
            "participantUrl": "https://avidanpprd.performnet.com/avidan/publicProfileView.do?method=display&clientState=pa2ACgl4vpWS%2BpZ87V1%2FbyZGwxG86w01FaG%2BppClfDESnEeZMtsZbjfxdpvqbAR3LQFYJbr4Ojf3%0A2sLWWd4%2Flq%2Fnp7qD4SAmsImyCJpwlOMheJyzZnCbsQeuvREzBSXj%2FCMEYbzqCXoffqBhjgy0mu8M%0AumLhIT2g8rNBV%2BxXdOUpyVCkd1jHsiWZ87E4ZfkuUa%2BRf9P7PlDL9Tg10Qb4uA05v%2FvoM1VDMEw9%0A30LikIuLV%2B%2F9tG%2F4XBE321Wzjfw8UbrKAIIeLqdDrDywhn2k7r8NOj4AVI4jT44%2BXw%3D%3D&cryptoPass=1",
            "jobName": "Employee",
            "departmentName": "Service",
            "orgName": "Colorado",
            "nodes": [
                {
                    "id": 3084,
                    "name": "Colorado"
                }
            ],
            "badges": [],
            "delegators": [],
            "alert": {
                "openNewWindow": false,
                "saGiftCode": false,
                "instantPoll": false,
                "isPlateauRedemption": false
            },
            "applicableSidebarModules": [],
            "urlEdit": "",
            "emailAddress": "avidantestemail@biworldwide.com",
            "organizationName": "Colorado",
            "isSelf": false,
            "largeAudience": false,
            "isThrowdownEnabled": false,
            "isSelected": false,
            "isLocked": false,
            "isPublic": true
        },
        {
            "id": 6806,
            "firstName": "John",
            "lastName": "Johnson",
            "countryName": "United States",
            "countryCode": "us",
            "countryRatio": 1.0,
            "allowPublicRecognition": true,
            "allowPublicInformation": true,
            "profileUrl": "",
            "participantUrl": "https://avidanpprd.performnet.com/avidan/publicProfileView.do?method=display&clientState=IgzfSaRJJXVdC4C237qwL3GZOFc0P9GR68xXgEGwyxtqPZGsvW0221%2FxL3BUPQGvk%2FSCiPWXqZTi%0AG0b2xlYdSunYcNlmATelZ6V%2BDt9VeEw9fb7veAOdIOGTn1zcGhiBnA1XvEp97y9e6gFuP%2BPsv25v%0A2ri%2F8bpwNJzUnPNTYpumjntSGPHQPXfDeRQd5ENs%2FG%2ByV1IbVCCpYHZaTmf2DdjpC13nkNsh%2FduD%0AmtQ1YNSuCFpD%2FifuH7VG%2BPpz9%2B81JK8kDT2cTSRxfr7cA%2BJFRCuIgoeQgIROYXuWVQ%3D%3D&cryptoPass=1",
            "jobName": "Employee",
            "departmentName": "Office",
            "orgName": "Kansas",
            "nodes": [
                {
                    "id": 3095,
                    "name": "Kansas"
                }
            ],
            "badges": [],
            "delegators": [],
            "alert": {
                "openNewWindow": false,
                "saGiftCode": false,
                "instantPoll": false,
                "isPlateauRedemption": false
            },
            "applicableSidebarModules": [],
            "urlEdit": "",
            "emailAddress": "avidantestemail@biworldwide.com",
            "organizationName": "Kansas",
            "isSelf": false,
            "largeAudience": false,
            "isThrowdownEnabled": false,
            "isSelected": false,
            "isLocked": false,
            "isPublic": true
        },
        {
            "id": 6996,
            "firstName": "Oneil",
            "lastName": "Johnson",
            "countryName": "India",
            "countryCode": "in",
            "countryRatio": 1.0,
            "allowPublicRecognition": true,
            "allowPublicInformation": true,
            "profileUrl": "",
            "participantUrl": "https://avidanpprd.performnet.com/avidan/publicProfileView.do?method=display&clientState=sXimsuywI0H5zB5IZZ%2Fhg%2F8faVXkg9W0B5IDqcRzGLNhCrf3S%2BU5J3uTlV2idLudONJfQOmP1Syf%0A38%2FRHfid8XuFqfTWzrIBLBFv0IIXrmSg77RhHETrgvc%2FIsdUiZo8kMV3B3wCQv%2B9DABC3VPZmEwJ%0AFjtpq%2F%2Bi%2BM7sF1pUIyxG4IFjV6ALOIv2LtqrzQTxiskbPuo3oZ%2BM4zIgGYNVjQ%2FzPEVbVZHlCqNg%0AR%2FDomARRN4PVsqhJN%2BrUVN9iYN9nzxz6Vr3IhguvSGGLUOWekUNOsqYeymKFFgj9gA%3D%3D&cryptoPass=1",
            "jobName": "Employee",
            "departmentName": "Service",
            "orgName": "Kolkata",
            "nodes": [
                {
                    "id": 6011,
                    "name": "Kolkata"
                }
            ],
            "badges": [],
            "delegators": [],
            "alert": {
                "openNewWindow": false,
                "saGiftCode": false,
                "instantPoll": false,
                "isPlateauRedemption": false
            },
            "applicableSidebarModules": [],
            "urlEdit": "",
            "emailAddress": "avidantestemail@biworldwide.com",
            "organizationName": "Kolkata",
            "isSelf": false,
            "largeAudience": false,
            "isThrowdownEnabled": false,
            "isSelected": false,
            "isLocked": false,
            "isPublic": true
        }
    ],
    "totalCount": 0,
    "allowBadge": false,
    "allowPublicRecFollowList": false
}