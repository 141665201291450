<div class="pageContainer pageWithBottomFooter"
    [ngClass]="{'noBorder': isParticipantModalOpen ||  isMessageModalOpen || isBehaviorModalOpen || isECardsModalOpen, 'ovh': isParticipantModalOpen ||  isMessageModalOpen || isBehaviorModalOpen || isECardsModalOpen}">

    <div class="otlkToast error" *ngIf="toast && toast.message!=''">
        <div class="otlkToastInner">
            <div class="otlkToastClose">
                <i class="gg-close otlkToastCloseIcon"></i>
            </div>
            <div class="otlkToastMessage">{{toast.message}}</div>
            <div class="otlkToastCloseAction">
                <i class="gg-close otlkToastCloseActionIcon" (click)="closeToast()"></i>
            </div>
        </div>
    </div>

    <div class="pageHeader">
        <h3>{{selectedPromotion.name}}</h3>
    </div>
    <div class="pageContent recogSectionsPage">
        <ul class="recogSectionsList">
            <li class="recogSectionsListItem" (click)="openParticipantModal()">
                <div class="recogSectionsListItemRow">
                    <div class="recogSectionsListItemColumnLeft flexGrow">
                        <div class="recogSectionsListItemColumnRow">
                            <span class="recogSectionsListItemTitle">
                                {{'Recognition.To' | translate}}
                            </span>
                            <!-- Add classes "mw50" and "ellipsis" to below span if the name needs to be trimmed after some width -->
                            <span class="otlkBadge dib ml5"
                                *ngIf="selectedParticipant.length>0">{{selectedParticipant[0].firstName}}
                                {{selectedParticipant[0].lastName}}</span>
                            <span class="dib othersCount" *ngIf="selectedParticipant.length>1">+
                                {{selectedParticipant.length - 1}} {{'Recognition.Others' | translate}}</span>
                        </div>
                    </div>
                    <div class="recogSectionsListItemColumnRight">
                        <i class="recogSectionsListIcon gg-chevron-right"></i>
                    </div>
                </div>
            </li>
            <li class="recogSectionsListItem" (click)="openMessageModal()">
                <div class="recogSectionsListItemRow">
                    <div class="recogSectionsListItemColumnLeft flexGrow">
                        <div class="recogSectionsListItemColumnRow">
                            <span class="recogSectionsListItemTitle">
                                {{'Recognition.MessageAndAwards' | translate}}
                            </span>
                        </div>
                        <div class="recogSectionsListItemColumnRow mt5">
                            <p class="recogSectionsListItemExtraInfo">
                                {{message.comments | slice:0:75}}{{message.comments.length > 75 ? '...' : ''}}                                
                            </p>
                        </div>
                        <div class="recogSectionsListItemColumnRow mt5" *ngIf="selectedPromotion.awardAvailable">
                            <p class="recogSectionsListItemExtraInfo recogSectionsListItemExtraInfo--small" *ngIf="!noBudget">
                                {{calculateAssignedPoints()}} {{'Recognition.PointsAwarded' | translate}} /
                                
                                {{calculateRemainingPoints(selectedPromotion?.budgetInfo?.currentValue)}} {{'Recognition.PointsRemaining' | translate}}
                            </p>
                            <p class="recogSectionsListItemExtraInfo recogSectionsListItemExtraInfo--small" *ngIf="noBudget">
                                {{calculateAssignedPoints()}} {{'Recognition.PointsAwarded' | translate}}
                            </p>
                        </div>
                    </div>
                    <div class="recogSectionsListItemColumnRight">
                        <i class="recogSectionsListIcon gg-chevron-right"></i>
                    </div>
                </div>
            </li>
            <li *ngIf="selectedPromotion.behaviors && selectedPromotion.behaviors.length>0" class="recogSectionsListItem" (click)="openBehaviorModal()">
                <div class="recogSectionsListItemRow">
                    <div class="recogSectionsListItemColumnLeft flexGrow">
                        <div class="recogSectionsListItemColumnRow">
                            <span class="recogSectionsListItemTitle">
                                {{'Recognition.Behavior' | translate}} <span *ngIf="!selectedPromotion.behaviorRequired">({{'Recognition.Optional' | translate}})</span>
                            </span>
                        </div>
                        <div class="recogSectionsListItemColumnRow mt5">
                            <p class="recogSectionsListItemExtraInfo recogSectionsListItemBadgeHandle dfr aic">                                
                                <img class="recogSectionsListItemBadgeHandleImage" *ngIf="selectedBehavior && selectedBehavior.img && selectedBehavior.img!=''" [src]="selectedBehavior.img" alt="Behavior Image">
                                <span class="recogSectionsListItemBadgeHandleText" *ngIf="selectedBehavior">{{selectedBehavior.name}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="recogSectionsListItemColumnRight">
                        <i class="recogSectionsListIcon gg-chevron-right"></i>
                    </div>
                </div>
            </li>
            <li *ngIf="selectedPromotion.ecards && selectedPromotion.ecards.length>0" class="recogSectionsListItem" (click)="openECardsModal()">
                <div class="recogSectionsListItemRow">
                    <div class="recogSectionsListItemColumnLeft flexGrow">
                        <div class="recogSectionsListItemColumnRow">
                            <span class="recogSectionsListItemTitle">
                                {{'Recognition.PhotoOrVideo' | translate}} ({{'Recognition.Optional' | translate}})
                            </span>
                        </div>
                        <div class="recogSectionsListItemColumnRow recogSectionsListItemColumnRow--flexInverse mt5"
                            *ngIf="selectedECard && selectedECard.url!=''">
                            <div class="recogSectionsListItemThumbnail">
                                <img [src]="selectedECard.url" alt="Thumbnail" style="width: 100%;">
                            </div>
                        </div>
                    </div>
                    <div class="recogSectionsListItemColumnRight">
                        <i class="recogSectionsListIcon gg-chevron-right"></i>
                    </div>
                </div>
            </li>
        </ul>

    </div>
    <div class="pageFooter">
        <div class="recogSectionsButtonsHolder">
            <button class="otlkBtn otlkBtn--secondary otlkBtn--inverse mr10 flexGrow" (click)="cancel()">
                {{'Recognition.Cancel' | translate}}
            </button>
            <button class="otlkBtn otlkBtn--primary flexGrow" [disabled]="enableSubmit() || isSubmitted" (click)="submit()">
                {{'Recognition.Send' | translate}}
            </button>
        </div>
    </div>

    <!-- This is the same popup div but I'm adding it with tabbed content -->
    <div
        [ngClass]="{'otlkParticipantPopup dfc popupHide': !isParticipantModalOpen, 'otlkParticipantPopup dfc popupShow': isParticipantModalOpen}">
        <participant-search-modal *ngIf="isParticipantModalOpen" class="otlkParticipantPopup dfc" [showRATab]="showRATab"
            [promotionId]="selectedPromotion.id" (closeModal)="closeParticipantModal()" [recognitionComponent] = "true">
        </participant-search-modal>
    </div>
    <!-- To Popup With Tabs Ends -->
    <div [ngClass]="{'otlkPopup dfc popupHide': !isMessageModalOpen, 'otlkPopup dfc popupShow': isMessageModalOpen}">
        <message-modal *ngIf="isMessageModalOpen" class="otlkPopup dfc" [selectedPromotion]="selectedPromotion"
            (closeModal)="closeMessageModal()">
        </message-modal>
    </div>

    <div [ngClass]="{'otlkPopup dfc popupHide': !isBehaviorModalOpen, 'otlkPopup dfc popupShow': isBehaviorModalOpen}">
        <behavior-modal *ngIf="isBehaviorModalOpen" class="otlkPopup dfc" [selectedPromotion]="selectedPromotion"
            (closeModal)="closeBehaviorModal()">
        </behavior-modal>
    </div>

    <div [ngClass]="{'otlkPopup dfc popupHide': !isECardsModalOpen, 'otlkPopup dfc popupShow': isECardsModalOpen}">
        <ecards-modal *ngIf="isECardsModalOpen" class="otlkPopup dfc" [selectedPromotion]="selectedPromotion"
            (closeModal)="closeECardsModal()">
        </ecards-modal>
    </div>
</div>